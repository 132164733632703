import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import FlexContainer from "../../../components/common/_flexContainer";
import Text from "../../../components/common/_text";
import SearchBar from "./SearchBar";
import styled from "styled-components";
import { StyledButtonPrimary } from "../../../components/common/_buttonNewOne";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import UserDetailsCell from "./UsersRolesTableComponents/UserDetailsCell";
import WorkspaceCountOrInviteCell from "./UsersRolesTableComponents/WorkspaceCountOrInviteCell";
import UserControlCell from "./UsersRolesTableComponents/UserControlCell";
import Divider from "../../../components/common/_divider";
import {
  Table,
  TableBody,
  TableData,
  TableRow,
  TableWrapper,
} from "../../../components/common/_table";
import axiosAdapter from "../../../utils";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import { InviteNewUserModal } from "./InviteNewUserModal";
import { AuthContext } from "../../../context/AuthContext";
import { RevokeInvitationModal } from "./RevokeInvitationModal";
import { UserRoleChangeModal } from "./UserRoleChangeModal";
import { env } from "../../../env";
import { CreateEditWorkspaceModalContext } from "../../../context/CreateEditWorkspaceModalContext";
import RemoveUserModal from "./UsersRolesTableComponents/RemoveUserModal";
import PropTypes from "prop-types";

const PrimaryButtonStyled = styled(StyledButtonPrimary)`
  height: 2.777rem;
  border-radius: 0.555rem;
`;

const getUsersAndRoles = async () => {
  let response = await axiosAdapter(
    "GET",
    env.REACT_APP_URL + "user-management/getUsersAndRoles",
  );
  return response.data;
};

const UsersRolesSectionContents = ({ setRefetchUsers, refetchWorkspaces }) => {
  const { showMessage } = useContext(SnackbarContext);
  const { userRole: accountRole, userEmail } = useContext(AuthContext);
  const { refetchWorkspacesMap } = useContext(CreateEditWorkspaceModalContext);

  const [userRoleData, setUserRoleData] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [userRoleLoading, setUserRoleLoading] = useState(true);
  const [columnFilters, setColumnFilters] = useState([]);

  const [isInviteUserModalOpen, setInviteUserModalOpen] = useState(false);
  const [isRevokeInviteModalOpen, setRevokeInviteModalOpen] = useState(false);
  const [revokeUserInviteRow, setRevokeUserInviteRow] = useState(null);

  const [isRoleChangeModalOpen, setRoleChangeModalOpen] = useState(false);
  const [roleChangeRow, setRoleChangeRow] = useState(null);

  const [removeUserRow, setRemoveUserRow] = useState(null);
  const [isRemoveUserModalOpen, setRemoveUserModalOpen] = useState(false);

  const refetchUsersAndRoles = useCallback(async () => {
    try {
      setUserRoleLoading(true);
      const response = await getUsersAndRoles();
      setUserCount(response.data.userCount);
      setUserRoleData(response.data.users);
      setUserRoleLoading(false);
    } catch (err) {
      console.log("Error: getUsersAndRoles", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to get the users and roles");
      }
      setUserRoleLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    const currentRefetchWorkspacesMap = refetchWorkspacesMap.current;
    currentRefetchWorkspacesMap.set(
      "UsersRolesSectionContents",
      refetchUsersAndRoles,
    );

    return () => {
      currentRefetchWorkspacesMap.delete("UsersRolesSectionContents");
    };
  }, [refetchUsersAndRoles, refetchWorkspacesMap]);

  useMemo(() => {
    (async () => {
      try {
        const response = await getUsersAndRoles();
        setUserCount(response.data.userCount);
        setUserRoleData(response.data.users);
        setUserRoleLoading(false);
        setRefetchUsers(() => refetchUsersAndRoles);
      } catch (err) {
        console.log("Error: getUsersAndRoles", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Unable to get the users and roles");
        }
        setUserRoleLoading(false);
      }
    })();
  }, [refetchUsersAndRoles, setRefetchUsers, showMessage]);

  const revokeUserInvite = useCallback((row) => {
    setRevokeUserInviteRow(row);
    setRevokeInviteModalOpen(true);
  }, []);

  const editUserRole = useCallback(
    (row, newRole) => {
      if (row?.role !== newRole.value) {
        setRoleChangeRow({ ...row, newRole: newRole.value });
        setRoleChangeModalOpen(true);
      } else {
        showMessage("Current role and new role of user cannot be same!");
      }
    },
    [showMessage],
  );

  const deleteUser = useCallback(
    (row) => {
      if (userEmail === row.emailId) {
        showMessage(
          "You cannot remove yourself, please use account deletion for that",
        );
      } else {
        setRemoveUserRow(row);
        setRemoveUserModalOpen(true);
      }
    },
    [showMessage, userEmail],
  );

  const handleResendInvite = useCallback(
    async (row) => {
      try {
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "user-management/resendInviteNewUser",
          {
            emailId: row.emailId,
          },
        );
        showMessage(
          "Invitation is resent successfully, you can resend again after 2 minutes",
          "success",
        );
      } catch (err) {
        console.log("Error: resendInviteNewUser", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Unable to resend the invitation");
        }
      }
    },
    [showMessage],
  );

  const columnHelper = useMemo(() => createColumnHelper(), []);
  const columns = useMemo(
    () => [
      columnHelper.accessor("fullName", {
        id: "userDetails",
        cell: (props) => <UserDetailsCell row={props.row.original} />,
        filterFn: "includesString",
        meta: { width: "calc(100% - 27.777rem)" },
      }),
      columnHelper.display({
        id: "workspaceCountOrInvite",
        cell: (props) => (
          <WorkspaceCountOrInviteCell
            row={props.row.original}
            handleResendInvite={handleResendInvite}
          />
        ),
        meta: { width: "13.888rem" },
      }),
      columnHelper.display({
        id: "userControl",
        cell: (props) => (
          <UserControlCell
            row={props.row.original}
            editUserRole={editUserRole}
            revokeUserInvite={revokeUserInvite}
            deleteUser={deleteUser}
          />
        ),
        meta: { width: "13.888rem" },
      }),
    ],
    [
      columnHelper,
      editUserRole,
      handleResendInvite,
      revokeUserInvite,
      deleteUser,
    ],
  );

  const table = useReactTable({
    data: userRoleData,
    columns,
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const usersRolesSearchValue = table.getColumn("userDetails").getFilterValue();
  const setUsersRolesSearchValue =
    table.getColumn("userDetails").setFilterValue;

  return (
    <>
      <FlexContainer $width="100%" $gap="1.111rem">
        <FlexContainer
          $flexDirection="column"
          $alignItems="flex-start"
          $gap="0.277rem"
        >
          <Text $fontWeight="bold" $fontSize="1.388rem">
            Users and Roles
          </Text>
          <Text $fontSize="0.833rem" $color="#777777">{`${userCount} ${
            userCount !== 1 ? "Members" : "Member"
          }`}</Text>
        </FlexContainer>
        <FlexContainer $gap="1.111rem">
          <SearchBar
            value={usersRolesSearchValue}
            setValue={setUsersRolesSearchValue}
            placeholderText="Search for Members"
            width="17.361rem"
          />
          <PrimaryButtonStyled
            width="12.222rem"
            onClick={() => setInviteUserModalOpen(true)}
            disabled={accountRole !== "ADMIN"}
          >
            Add Members
          </PrimaryButtonStyled>
        </FlexContainer>
        {table.getRowModel().rows.length ? (
          <TableWrapper $maxHeight="24.372rem">
            <Table $width="max(52.083rem, 100%)">
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} $border="0.138rem solid #2f2f2f">
                    {row.getVisibleCells().map((cell) => (
                      <TableData
                        key={cell.id}
                        $width={cell.column.columnDef.meta.width}
                        $padding="1.111rem 1.111rem 1.111rem 0"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableData>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        ) : (
          <>
            <Divider />
            <Text $width="100%" $textAlign="center">
              {(() => {
                if (userRoleLoading) {
                  return "Loading Users and Roles...";
                }

                if (userRoleData.length) {
                  return "No member found for the given search criteria";
                }

                return "No members are created in this organisation";
              })()}
            </Text>

            <Divider />
          </>
        )}
      </FlexContainer>
      {isInviteUserModalOpen && (
        <InviteNewUserModal
          isOpen={isInviteUserModalOpen}
          onClose={() => setInviteUserModalOpen(false)}
          refetchUserRoles={refetchUsersAndRoles}
        />
      )}
      {isRevokeInviteModalOpen && (
        <RevokeInvitationModal
          isOpen={isRevokeInviteModalOpen}
          onClose={() => {
            setRevokeInviteModalOpen(false);
            setRevokeUserInviteRow(null);
          }}
          emailId={revokeUserInviteRow?.emailId}
          refetchUsersAndRoles={refetchUsersAndRoles}
        />
      )}
      {isRoleChangeModalOpen && (
        <UserRoleChangeModal
          isOpen={isRoleChangeModalOpen}
          onClose={() => {
            setRoleChangeModalOpen(false);
            setRoleChangeRow(null);
          }}
          dataRow={roleChangeRow}
          refetchUsersAndRoles={() => {
            refetchUsersAndRoles();
            refetchWorkspaces();
          }}
        />
      )}
      {isRemoveUserModalOpen && (
        <RemoveUserModal
          isOpen={isRemoveUserModalOpen}
          onClose={() => {
            setRemoveUserModalOpen(false);
            setRemoveUserRow(null);
          }}
          dataRow={removeUserRow}
          refetchUsersAndRoles={() => {
            refetchUsersAndRoles();
            refetchWorkspaces();
          }}
        />
      )}
    </>
  );
};

const userShape = {
  emailId: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  workspaceCount: PropTypes.number.isRequired,
  inviteStatus: PropTypes.string.isRequired,
};

UserDetailsCell.propTypes = {
  row: PropTypes.oneOfType([
    PropTypes.shape(userShape),
    PropTypes.shape({
      original: PropTypes.shape(userShape).isRequired,
    }),
  ]).isRequired,
};

UserControlCell.propTypes = {
  row: PropTypes.oneOfType([
    PropTypes.shape(userShape),
    PropTypes.shape({
      original: PropTypes.shape(userShape).isRequired,
    }),
  ]).isRequired,
  editUserRole: PropTypes.func.isRequired,
  revokeUserInvite: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
};

WorkspaceCountOrInviteCell.propTypes = {
  row: PropTypes.oneOfType([
    PropTypes.shape(userShape),
    PropTypes.shape({
      original: PropTypes.shape(userShape).isRequired,
    }),
  ]).isRequired,
  handleResendInvite: PropTypes.func.isRequired,
};

export default UsersRolesSectionContents;
