import styled, { keyframes } from "styled-components";
import ConnectorIcon from "../../../assets/images/connector_node_preview_icon.svg";
import SettingsIcon from "../../../assets/images/settings_icon.svg";
import { ReactComponent as InspectIcon } from "../../../assets/images/node_status_inspect_icon.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/images/node_status_error_icon.svg";
import { ReactComponent as RunningIcon } from "../../../assets/images/node_status_running_icon.svg";
import { useMemo, useState } from "react";
import { Handle, Position } from "reactflow";
import PropTypes from "prop-types";
const StyledInspectIcon = styled(InspectIcon)`
  height: 12px;
  width: 12px;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  height: 12px;
  width: 12px;
`;

const StyledRunningIcon = styled(RunningIcon)`
  height: 12px;
  width: 12px;
  & path {
    stroke: ${(props) => getCategoryColor(props.category)};
  }
`;

const CardContainer = styled.div`
  width: 256px;
  height: 126px;
  max-width: 256px;
  max-height: 126px;
  border-radius: 12px;
  background-color: #2f2f2f;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  box-shadow: 0px 0px 40px 0px #00000029;
`;

const LeftLine = styled.div`
  width: 4px;
  background-color: #47ccd6;
  border-radius: 5px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-left: 12px;
  overflow: hidden;
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: baseline;
`;

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NodeStatusContainer = styled.div`
  height: 20px;
  border: 1px solid #f6f6f6;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.Running {
    background: ${(props) => getBackgroundColor(props.category)};
    border-color: ${(props) => getCategoryColor(props.category)};
  }

  &.Inspect {
    background: #edb62e29;
    border-color: #edb62e;
  }

  &.Error {
    background: #ea3a3a29;
    border-color: #ea3a3a;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 2px solid rgba(76, 74, 74, 0.1);
  border-top: 2px solid ${(props) => getCategoryColor(props.category)};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: ${spin} 0.7s linear infinite;
`;

const NodeStatusDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TitleAndIconRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 190px;
`;

const RunningStatus = ({ category }) => {
  return (
    <NodeStatusDiv>
      <StyledRunningIcon category={category} />
      <Text style={{ fontSize: "10px", color: getCategoryColor(category) }}>
        Running
      </Text>
    </NodeStatusDiv>
  );
};

const InspectStatus = () => (
  <NodeStatusDiv>
    <StyledInspectIcon />
    <Text style={{ fontSize: "10px", color: "#EDB62E", lineHeight: "12px" }}>
      Inspect
    </Text>
  </NodeStatusDiv>
);

const ErrorStatus = () => (
  <NodeStatusDiv>
    <StyledErrorIcon />
    <Text
      style={{
        fontSize: "10px",
        color: "#EA3A3A",
        lineHeight: "12px",
      }}
    >
      Error
    </Text>
  </NodeStatusDiv>
);

const displayNodeStatus = (status, category) => {
  switch (status) {
    case "Running":
      return <RunningStatus category={category} />;
    case "Inspect":
      return <InspectStatus />;
    case "Error":
      return <ErrorStatus />;
    default:
      return <Spinner category={category} />;
  }
};

const getCategoryColor = (category) => {
  switch (category) {
    case "Stream Connector":
      return "#6DC89B";
    case "Store Connector":
      return "#B5E3CE";
    case "Application":
      return "#EB6F95";
    case "Telematics Device":
      return "#F7C0D5";
    default:
      return "#EB6F95";
  }
};

const getBackgroundColor = (category) => {
  switch (category) {
    case "Stream Connector":
      return "#6DC89B29";
    case "Store Connector":
      return "#B5E3CE29";
    case "Application":
      return "#EB6F9529";
    case "Telematics Device":
      return "#F7C0D529";
    default:
      return "#EB6F9529";
  }
};

const PipelineConnectorCard = (props) => {
  const {
    title,
    connectionType,
    category,
    name,
    interfaces,
    isConfigure,
    status = "",
  } = props.data;

  const [connection, setConnection] = useState({ left: false, right: false });

  useMemo(() => {
    const left = interfaces.some((topic) => topic.direction === "input");
    const right = interfaces.some((topic) => topic.direction === "output");
    setConnection({ left, right });
  }, [interfaces]);

  return (
    <>
      {connection.left && (
        <Handle
          style={{
            backgroundColor: "#B0B0B0",
            cursor: "not-allowed",
            border: "none",
          }}
          type="target"
          position={Position.Left}
        />
      )}
      <CardContainer>
        <LeftLine style={{ backgroundColor: getCategoryColor(category) }} />
        <ContentContainer>
          <ContentRow style={{ gap: "8px" }}>
            <TitleAndIconRow>
              <IconContainer>
                <img src={ConnectorIcon} alt="Connector Icon" />
              </IconContainer>
              <TitleAndSubtitleContainer>
                {title && (
                  <Text
                    style={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#F6F6F6",
                      fontFamily: "Articulat CF Medium",
                      fontWeight: "bold",
                    }}
                  >
                    {title}
                  </Text>
                )}
                {name && (
                  <Text
                    style={{
                      fontSize: "10px",
                      lineHeight: "14px",
                      color: "#777777",
                      fontFamily: "Articulat CF Medium",
                      fontWeight: "bold",
                    }}
                  >
                    {name}
                  </Text>
                )}
              </TitleAndSubtitleContainer>
            </TitleAndIconRow>
            <IconContainer style={{ width: "20px" }}>
              <img src={SettingsIcon} alt="Connector Icon" />
            </IconContainer>
          </ContentRow>
          <ContentRow style={{ gap: "8px" }}>
            <TitleAndSubtitleContainer
              style={{ width: "50%", maxWidth: "50%" }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  lineHeight: "12px",
                  color: "#777777",
                  fontFamily: "Articulat CF Medium",
                  fontWeight: "bold",
                }}
              >
                Connector Category
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "18px",
                  color: getCategoryColor(category),
                }}
              >
                {category}
              </Text>
            </TitleAndSubtitleContainer>
            <TitleAndSubtitleContainer
              style={{ width: "50%", maxWidth: "50%" }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  lineHeight: "12px",
                  color: "#777777",
                  fontFamily: "Articulat CF Medium",
                  fontWeight: "bold",
                }}
              >
                Connection Type
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "18px",
                  color: getCategoryColor(category),
                }}
              >
                {connectionType}
              </Text>
            </TitleAndSubtitleContainer>
          </ContentRow>
          <ContentRow style={{ gap: "8px" }}>
            <Text
              style={{ fontSize: "12px", lineHeight: "16px", color: "#47CCD6" }}
            >
              {isConfigure ? "Configure" : ""}
            </Text>
            <NodeStatusContainer
              className={status || "Running"}
              category={category}
            >
              {displayNodeStatus(status, category)}
            </NodeStatusContainer>
          </ContentRow>
        </ContentContainer>
      </CardContainer>
      {connection.right && (
        <Handle
          style={{
            backgroundColor: "#B0B0B0",
            cursor: "not-allowed",
            border: "none",
          }}
          type="source"
          position={Position.Right}
        />
      )}
    </>
  );
};

RunningStatus.propTypes = {
  category: PropTypes.oneOf([
    "Stream Connector",
    "Store Connector",
    "Application",
    "Telematics Device",
  ]).isRequired,
};
PipelineConnectorCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    connectionType: PropTypes.string.isRequired,
    category: PropTypes.oneOf([
      "Stream Connector",
      "Store Connector",
      "Application",
      "Telematics Device",
    ]).isRequired,
    name: PropTypes.string.isRequired,
    interfaces: PropTypes.arrayOf(
      PropTypes.shape({
        direction: PropTypes.oneOf(["input", "output"]).isRequired,
      }),
    ).isRequired,
    isConfigure: PropTypes.bool.isRequired,
    status: PropTypes.oneOf(["Running", "Inspect", "Error", ""]),
  }).isRequired,
};

export default PipelineConnectorCard;
