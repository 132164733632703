import React, { useCallback, useContext } from "react";
import PipelineConnectorCard from "../../Pipelines/Components/PipelineConnectorCard";
import PipelineBlankCard from "../../Pipelines/Components/PipelineBlankCard";
import { useHistory } from "react-router-dom";
import ReactFlow, { Background, ConnectionLineType } from "reactflow";
import { remToPx } from "../../../utils";
import styled from "styled-components";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import TopicEdge from "../../Pipelines/Components/TopicEdge";
import { ReactComponent as CloseIcon } from "../../../assets/images/Close.svg";
import PropTypes from "prop-types";

const StyledPlusIcon = styled(CloseIcon)`
  height: 1.666rem;
  width: 1.666rem;
  transform: rotate(-45deg);
  path {
    stroke: #f6f6f6;
  }
`;

const nodeTypes = {
  OUTPUT: PipelineConnectorCard,
  INPUT: PipelineConnectorCard,
  Input: PipelineConnectorCard,
  Output: PipelineConnectorCard,
  BLANK: PipelineBlankCard,
  "Custom Connector": PipelineConnectorCard,
  GEOFENCE_TRANSFORM: PipelineConnectorCard,
};

const edgeTypes = {
  topic: TopicEdge,
};

const BackgroundStyled = styled(Background)`
  background: #0d0d0d;
`;

const CornerButton = styled.button`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 4;
  background-color: #47ccd6;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0.833rem;
  :disabled {
    cursor: not-allowed;
    background-color: #777777;
  }
`;

const PipelinesGraph = ({ nodes, edges, isNodeClickable }) => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);
  const onNodeSelectedChange = useCallback(
    (_, node) => {
      if (node?.data?.configId && isNodeClickable) {
        history.push("/connectorInformation", {
          nodeData: node.data,
          nodes: nodes,
          edges,
        });
      } else if (node?.data?.configId && !isNodeClickable) {
        showMessage("You don't have access to perform this operation");
      }
    },
    [edges, history, isNodeClickable, nodes, showMessage],
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      nodesConnectable={false}
      onNodeClick={onNodeSelectedChange}
      connectionLineType={ConnectionLineType.Bezier}
      edgeTypes={edgeTypes}
      fitView={nodes.length > 4}
    >
      <CornerButton
        disabled={!isNodeClickable}
        onClick={(e) => {
          e.stopPropagation();
          if (isNodeClickable) {
            history.push("/connectors");
          } else {
            showMessage("You don't have access to perform this operation");
          }
        }}
      >
        <StyledPlusIcon />
      </CornerButton>
      <BackgroundStyled
        variant="dots"
        gap={remToPx(0.555)}
        size={remToPx(0.277)}
        color="#1C1C1C"
      />
    </ReactFlow>
  );
};

PipelinesGraph.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      data: PropTypes.shape({
        configId: PropTypes.string,
      }),
      position: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ).isRequired,

  edges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired,
      type: PropTypes.string,
    }),
  ).isRequired,

  isNodeClickable: PropTypes.bool.isRequired,
};

export default PipelinesGraph;
