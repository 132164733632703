import BackIcon from "../../assets/images/left_arrow_icon.svg";
import condenseLogo from "../../assets/images/condenseLogo.svg";
import supportImg from "../../assets/images/supportImg1.png";
import discord from "../../assets/images/discord.png";
import technicalDocs from "../../assets/images/technicalDocs.png";
import { useHistory, withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import CustomRow from "../../components/common/_customRow";
import styled from "styled-components";
import PropTypes from "prop-types";

const SupportPage = () => {
  const history = useHistory();
  const [isSupportTime, setIsSupportTime] = useState(false);

  const handleBackButtonClick = () => {
    history.push("/");
  };

  useEffect(() => {
    let currentHour = moment().hours();
    if (currentHour >= 10 && currentHour < 19) {
      setIsSupportTime(true);
    } else {
      setIsSupportTime(false);
    }
  });

  const Button = styled.div`
    font-family: "Articulat CF Medium";
    height: 2.5rem;
    display: flex;
    align-items: center;
    padding-left: 0.25rem;
    border: 0.12rem solid #ececec;
    width: 10rem;
    cursor: pointer;
  `;

  const GoBackText = styled.div`
    color: #ececec;
    font-size: 1.8rem;
    font-weight: bold;
    padding-left: 0.5rem;
  `;

  const CondenseLogo = styled.div`
    padding-left: 30rem;
  `;

  const SupportArea = styled.div`
    margin-top: 3rem;
    display: flex;
    gap: 1rem;
    height: 100%;
  `;

  const SupportImage = styled.img`
    width: 100%;
    height: 60%;
  `;

  const SupportLeft = styled.div`
    width: 50%;
  `;

  const SupportRight = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `;

  const TechDocs = styled.img`
    width: 100%;
    height: 50%;
  `;

  const TextArea = styled.div`
    margin-top: 1rem;
  `;

  const EmailSupport = styled.div`
    color: #ececec;
    font-size: 1.112rem;
    font-weight: bold;
  `;
  const Text = styled.div`
    font-family: "Articulat CF Medium";
    font-size: 2.22rem;
    font-weight: bold;
    color: #ececec;
  `;

  const Discord = styled.img`
    width: 100%;
    height: 45%;
  `;

  Availability.propTypes = {
    isAvailable: PropTypes.bool.isRequired,
  };

  const Availability = styled.div`
    color: ${(props) => (props.isAvailable ? "#37D184" : "#EA3D4A")};
    display: flex;
    align-items: center;
    padding-top: 0.38rem;
  `;

  const AvailabilityDot = styled.div`
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: ${(props) => (props.isAvailable ? "#37D184" : "#EA3D4A")};
    margin-right: 0.5rem;
  `;

  AvailabilityDot.propTypes = {
    isAvailable: PropTypes.bool.isRequired,
  };
  return (
    <div
      style={{
        backgroundColor: "#171717",
        height: "100vh",
        padding: "3.5rem",
        overflowY: "none",
      }}
    >
      <CustomRow justifyContent="none">
        <Button
          onClick={handleBackButtonClick}
          style={{ height: "3.5rem", width: "12rem" }}
        >
          <img
            src={BackIcon}
            style={{ height: "2rem", width: "2rem" }}
            alt=""
          />
          <GoBackText>Go Back</GoBackText>
        </Button>
        <CondenseLogo>
          <img src={condenseLogo} style={{ height: "2.15rem" }} alt="" />
        </CondenseLogo>
      </CustomRow>
      <SupportArea>
        <SupportLeft>
          <SupportImage src={supportImg} />
          <TextArea>
            <Text>Lost in the flow? Get in touch with us</Text>
            <Text
              style={{
                fontSize: "1.25rem",
                opacity: 0.7,
                fontWeight: "normal",
              }}
            >
              We would love to hear from you, and help you resolve your queries.
            </Text>
            <Button
              style={{
                width: "12rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <a
                href="mailto:support@zeliot.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <EmailSupport> Email Support</EmailSupport>
              </a>
            </Button>
            <Availability isAvailable={isSupportTime}>
              <AvailabilityDot isAvailable={isSupportTime} />
              {isSupportTime ? "Available Now" : "Available 10 AM - 7 PM IST"}
            </Availability>
          </TextArea>
        </SupportLeft>
        <SupportRight>
          <TechDocs src={technicalDocs} />
          <Discord src={discord} />
        </SupportRight>
      </SupportArea>
    </div>
  );
};

export default withRouter(SupportPage);
