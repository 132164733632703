import styled from "styled-components";
import CountryCodes from "country-codes-list";
import { ReactComponent as UpIcon } from "../../assets/images/up_icon.svg";
import { ReactComponent as DownIcon } from "../../assets/images/down_icon.svg";
import search_icon from "../../assets/images/search_icon.svg";
import { useState } from "react";
import PropTypes from "prop-types";

const countryCodes = CountryCodes.customList(
  "countryNameEn",
  "{countryCallingCode}",
);
const countryCodeArray = Object.entries(countryCodes).map(
  ([country, code]) => ({
    country,
    code: code.replace(" ", ""),
  }),
);

const StyledUpIcon = styled(UpIcon)`
  height: 1.111rem;
  width: 1.111rem;
  :hover {
    cursor: pointer;
  }
`;

const StyledDownIcon = styled(DownIcon)`
  height: 1.111rem;
  width: 1.111rem;
  :hover {
    cursor: pointer;
    path {
      stroke: ${({ $hoverIconColor }) => $hoverIconColor || "#F6F6F6"};
    }
  }
`;

const ContryCodeDropDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.555rem;
  background-color: #2f2f2f;
  padding: 1.1455rem 0.8675rem;
  position: relative;
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
  `}
  ${({ addBorder }) =>
    addBorder &&
    `
    padding: 1.111rem 0.833rem;
    border: 0.069rem solid #b0b0b0;
  `}
`;

const CountryCodeOptionsContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  background-color: #2f2f2f;
  border-radius: 0.555rem;
  border: 0.069rem solid #777777;
  width: 18.055rem;
  height: 15.277rem;
  padding: 1.111rem;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const CountryCodeOption = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.555rem;
  &:hover {
    border: 0.069rem solid #777777;
    border-radius: 0.555rem;
  }
`;

const CountryCodeChipContainer = styled.div`
  padding: 0.138rem 0.416rem;
  border-radius: 0.555rem;
  background-color: #4b4b4b;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  color: #f6f6f6;
  font-size: 1.527rem;
  line-height: 2.166rem;
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #2f2f2f;
`;

export const CountryCodeSelector = ({
  selectedContryCode,
  onContryCodeSelect,
  disabled = false,
  addBorder = false,
}) => {
  const [isCCselectorOpen, setIsCCselectorOpen] = useState(false);
  const [countrySearchText, setCountrySearchText] = useState("");

  return (
    <ContryCodeDropDown disabled={disabled} addBorder={addBorder}>
      <Text
        style={{
          fontSize: "1.111rem",
          lineHeight: "1.111rem",
          marginRight: "0.555rem",
          color: "#CCCCCC",
          cursor: "pointer",
        }}
        onClick={() => setIsCCselectorOpen(!isCCselectorOpen)}
      >{`+${selectedContryCode}`}</Text>
      {isCCselectorOpen ? (
        <StyledUpIcon onClick={() => setIsCCselectorOpen(!isCCselectorOpen)} />
      ) : (
        <StyledDownIcon
          onClick={() => setIsCCselectorOpen(!isCCselectorOpen)}
        />
      )}
      {isCCselectorOpen && (
        <>
          <button
            onClick={() => setIsCCselectorOpen(!isCCselectorOpen)}
            style={{
              zIndex: "99",
              width: "100vw",
              height: "100vh",
              position: "fixed",
              top: "0",
              left: "0",
              background: "transparent",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
            aria-label="Toggle Credit Card selector"
          ></button>

          <CountryCodeOptionsContainer>
            <InputFieldContainer
              style={{
                padding: "0.416rem 0.416rem",
                borderRadius: "0.277rem",
                backgroundColor: "#0D0D0D",
                height: "2.222rem",
                gap: "0.555rem",
                marginBottom: "0.555rem",
              }}
            >
              <img
                src={search_icon}
                alt="search_icon"
                style={{ height: "1.041rem", width: "1.041rem" }}
              />
              <InputField
                placeholder="Search for Country"
                onChange={(e) => setCountrySearchText(e.target.value)}
                value={countrySearchText}
                style={{
                  fontSize: "1.041rem",
                  lineHeight: "1.041rem",
                }}
              />
            </InputFieldContainer>
            {countryCodeArray
              .filter((item) =>
                item.country
                  .toLowerCase()
                  .includes(countrySearchText.toLowerCase()),
              )
              .map((item) => (
                <CountryCodeOption
                  onClick={() => {
                    onContryCodeSelect(item);
                    setIsCCselectorOpen(!isCCselectorOpen);
                  }}
                  key={item.country}
                >
                  <Text
                    style={{
                      fontSize: "0.972rem",
                      lineHeight: "0.972rem",
                      color: "#CCCCCC",
                      width: "70%",
                    }}
                  >
                    {item.country}
                  </Text>
                  <CountryCodeChipContainer>
                    <Text
                      style={{
                        fontSize: "0.972rem",
                        lineHeight: "0.972rem",
                        color: "#CCCCCC",
                        width: "25%",
                      }}
                    >{`+${item.code}`}</Text>
                  </CountryCodeChipContainer>
                </CountryCodeOption>
              ))}
          </CountryCodeOptionsContainer>
        </>
      )}
    </ContryCodeDropDown>
  );
};
CountryCodeSelector.propTypes = {
  selectedContryCode: PropTypes.string.isRequired,
  onContryCodeSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  addBorder: PropTypes.bool,
};

CountryCodeSelector.defaultProps = {
  disabled: false,
  addBorder: false,
};
