import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Applicationcard from "./components/Applicationcard";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import PropTypes from "prop-types";

const PageContainer = styled.div`
  width: 100%;
  height: calc(100vh - 3.95rem);
  background-color: #0d0d0d;
  padding: 1.111rem;
`;

const Text = styled.p`
  margin: 0;
  color: #f6f6f6;
  font-size: 1.666rem;
  font-weight: bold;
`;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

function ApplicationList({ data, onDelete, refetchApplications }) {
  const { showMessage } = useContext(SnackbarContext);

  const [applications, setApplications] = useState(data);
  const history = useHistory();

  useEffect(() => {
    setApplications(data);
  }, [data]);

  const handleDelete = async (id) => {
    try {
      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/deleteApplication",
        {
          applicationId: id,
        },
      );
      if (response.status === 200) {
        setApplications((prevApplications) =>
          prevApplications.filter((app) => app.id !== id),
        );
        onDelete(id);
      }
    } catch (err) {
      console.log("Error: deleteApplication", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to delete the Application");
      }
    }
  };

  const handleClick = () => {
    history.push("/createApplication");
  };

  return (
    <PageContainer>
      <CustomRow>
        <CustomColumn
          mb={1.111}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <Text>Applications</Text>
          <StyledButtonPrimary
            style={{ width: "14.166rem" }}
            onClick={() => handleClick()}
          >
            Add New Application
          </StyledButtonPrimary>
        </CustomColumn>
        <CustomColumn>
          <CustomRow
            style={{
              justifyContent: "normal",
              alignItems: "stretch",
              gap: "0.833rem",
            }}
          >
            {applications.map((eachObj) => (
              <Column
                key={eachObj.id}
                style={{
                  width: "calc(25% - (0.62475rem))",
                }}
              >
                <Applicationcard
                  application={eachObj}
                  onDelete={handleDelete}
                  refetchApplications={refetchApplications}
                />
              </Column>
            ))}
          </CustomRow>
        </CustomColumn>
      </CustomRow>
    </PageContainer>
  );
}

ApplicationList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  refetchApplications: PropTypes.func.isRequired,
};

export default ApplicationList;
