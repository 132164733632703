import moment from "moment";
import styled from "styled-components";
import CustomRow from "../common/_customRow";
import ExpandLeft from "../../assets/images/expand_more_24px.svg";
import Block from "../../assets/images/Blogs background.png";

import PropTypes from "prop-types";

/**
 *NOTE: Common Component
 * @description This Component will show Users Profile and Account creation Date.
 * @param {*} props
 * @return {*}
 */
const MainContainer = styled.div`
  background-color: #2f2f2f;
  display: flex;
  flex-direction: column;
  border-radius: 0.84rem;
  padding-bottom: 1.112rem;
  gap: 1.112rem;
`;
const NameDiv = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 1.112rem;
  padding-right: 1.112rem;
`;
const ProfileText = styled.p`
  font-size: 1.39rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  color: #f6f6f6;
  line-height: 1.8rem;
  margin: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;

const CreateCard = styled.p`
  color: #777777;
  font-size: 0.84rem;
  font-family: "Articulat CF Medium";
  margin: 0px;
`;

const DescriptionText = styled.p`
  font-size: 0.972rem;
  font-family: "Articulat CF Medium";
  line-height: 1.39rem;
  color: #b0b0b0;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;
const ReadText = styled.p`
  color: #47ccd6;
  font-size: 0.972rem;
  font-weight: 500;
  font-family: "Articulat CF Medium";
  margin: 0px;
`;

const WorkspaceBlock = (props) => {
  let { eachBlog } = props;
  return (
    <MainContainer>
      <img
        src={eachBlog.image_url || Block}
        style={{
          width: "100%",
          height: "11.5rem",
          borderRadius: "0.84rem 0.84rem 0 0",
          objectFit: "cover",
        }}
        alt="blog"
      />
      <NameDiv>
        <ProfileText className="multiline-ellipsis ">
          {eachBlog.title}
        </ProfileText>
      </NameDiv>
      <NameDiv>
        <DescriptionText className="multiline-ellipsis ">
          {eachBlog.description}
        </DescriptionText>
      </NameDiv>
      <CustomRow style={{ padding: "0.55rem 1.112rem 0px 1.112rem" }}>
        <CreateCard>{`${moment(Date.now()).format("DD MMM YYYY")}`}</CreateCard>
        <CustomRow style={{ gap: "0.55rem" }}>
          <a
            href={eachBlog.blog_url}
            target="_blank"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: "0.55rem",
            }}
          >
            <ReadText>Read More</ReadText>
            <img
              src={ExpandLeft}
              style={{ width: "0.972rem", height: "0.972rem" }}
              alt="ExpandLeft"
            />
          </a>
        </CustomRow>
      </CustomRow>
    </MainContainer>
  );
};

WorkspaceBlock.propTypes = {
  eachBlog: PropTypes.shape({
    image_url: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    blog_url: PropTypes.string,
  }).isRequired,
};

export default WorkspaceBlock;
