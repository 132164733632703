import React from "react";
import FlexContainer from "../../../../components/common/_flexContainer";
import Text from "../../../../components/common/_text";
import Tooltip from "../../../../components/common/_tooltip";
import PropTypes from "prop-types"; // Import PropTypes

const WorkspaceDescriptionCell = ({ row }) => {
  return (
    <FlexContainer $width="100%" $gap="0.277rem">
      <Text $width="100%" $fontSize="0.833rem" $color="#B0B0B0">
        Description
      </Text>
      <Tooltip title={row.description} placement="bottom">
        <Text $noWrap>{row.description}</Text>
      </Tooltip>
    </FlexContainer>
  );
};

WorkspaceDescriptionCell.propTypes = {
  row: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default WorkspaceDescriptionCell;
