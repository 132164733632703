import React from "react";
import FlexContainer from "../../../../components/common/_flexContainer";
import Text from "../../../../components/common/_text";
import styled from "styled-components";
import Tooltip from "../../../../components/common/_tooltip";
import { workspaceAvatarMap } from "../../../../components/common/_workspaceAvatarMap";
import PropTypes from "prop-types"; // Import PropTypes

const FlexItem = styled.div`
  width: 100%;
`;
const AvatarIcon = styled.div`
  height: 2.777rem;
  width: 2.777rem;
`;

const WorkspaceNameCell = ({ row }) => {
  return (
    <FlexContainer $width="100%" $gap="0.833rem">
      <AvatarIcon as={workspaceAvatarMap[row.iconFile]} />
      <FlexContainer $width="calc(100% - 2.777rem - 0.833rem)" $gap="0.277rem">
        <FlexItem>
          <Tooltip title={row.workspaceName} placement="bottom">
            <Text
              $maxWidth="min-content"
              $fontWeight="bold"
              $fontSize="1.111rem"
              $noWrap
            >
              {row.workspaceName}
            </Text>
          </Tooltip>
        </FlexItem>
        <FlexItem>
          <Tooltip title={row.createdBy} placement="bottom">
            <Text
              $maxWidth="min-content"
              $fontSize="0.833rem"
              $color="#777777"
              $noWrap
            >{`Created by ${row.createdBy}`}</Text>
          </Tooltip>
        </FlexItem>
      </FlexContainer>
    </FlexContainer>
  );
};

WorkspaceNameCell.propTypes = {
  row: PropTypes.shape({
    iconFile: PropTypes.string.isRequired,
    workspaceName: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
  }).isRequired,
};

export default WorkspaceNameCell;
