import styled from "styled-components";
import { ReactComponent as SearchIcon } from "../../assets/images/Search.svg";
import { useRef } from "react";
import PropTypes from "prop-types";
const SearchInputWrapper = styled.div`
  position: relative;
  width: ${({ $width }) => $width || ""};
`;

const SearchInput = styled.input`
  font-family: "Articulat CF Medium";
  width: ${({ $width }) => $width || ""};
  background: ${({ $backgroundColor }) => $backgroundColor};
  border: ${({ $border }) => $border};
  border-radius: 0.555rem;
  padding: ${({ $padding }) => $padding};
  padding-left: ${({ $padding, $iconWidth }) =>
    $iconWidth && $padding
      ? `calc(${$iconWidth} + ${$padding} + ${$padding})`
      : $padding || ""};
  color: ${({ $fontColor }) => $fontColor};
  font-size: ${({ $fontSize }) => $fontSize};
  line-height: ${({ $lineHeight }) => $lineHeight};
  ::placeholder {
    color: ${({ $placeholderColor }) => $placeholderColor};
    font-weight: ${({ $placeholderWeight }) => $placeholderWeight};
    font-size: ${({ $fontSize }) => $fontSize};
  }
  :focus-visible {
    outline: none;
  }
`;

const SearchIconStyled = styled(SearchIcon)`
  width: ${({ $iconWidth }) => $iconWidth || ""};
  height: ${({ $iconWidth }) => $iconWidth || ""};
  background: transparent;
  position: absolute;
  top: calc(50% - ${({ $iconWidth }) => $iconWidth || ""} / 2);
  left: ${({ $padding }) => $padding || ""};
  cursor: text;
  & path {
    stroke: ${({ $iconColor }) => $iconColor};
  }
  :hover {
    path {
      stroke: ${({ $hoverIconColor }) => $hoverIconColor || "#F6F6F6"};
    }
  }
`;

/**
 * A generic search bar component.
 *
 * @param {string} [placeholder="Search"] the placeholder text to show in the search bar
 * @param {string} [width="16.52rem"] the width of the search bar
 * @param {string} [border="0.069rem solid #B0B0B0"] the border of the search bar
 * @param {string} [backgroundColor="inherit"] the background color of the search bar
 * @param {string} [iconColor="#B0B0B0"] the color of the search icon
 * @param {string} [iconSize="1.111rem"] the size of the search icon
 * @param {string} [placeholderColor="#B0B0B0"] the color of the placeholder text
 * @param {string} [placeholderWeight="bold"] the weight of the placeholder text
 * @param {string} [fontColor="#F6F6F6"] the color of the text
 * @param {string} [fontSize="0.972rem"] the font size of the text
 * @param {string} [lineHeight="1.111rem"] the line height of the text
 * @param {string} [containerPadding="0.763rem"] the padding of the container
 * @param {string} searchText the current search text in the search bar (mandatory)
 * @param {function(string):void} onSearchTextChange the callback to call when the search text changes (mandatory)
 */
const GenericSearchBar = ({
  placeholder = "Search",
  width = "16.52rem",
  border = "0.069rem solid #B0B0B0",
  backgroundColor = "inherit",
  iconColor = "#B0B0B0",
  iconSize = "1.111rem",
  placeholderColor = "#B0B0B0",
  placeholderWeight = "bold",
  fontColor = "#F6F6F6",
  fontSize = "0.972rem",
  lineHeight = "1.111rem",
  containerPadding = "0.763rem",
  searchText,
  onSearchTextChange,
}) => {
  const searchInputRef = useRef(null);

  return (
    <SearchInputWrapper $width={width}>
      <SearchIconStyled
        $iconColor={iconColor}
        $padding={containerPadding}
        $iconWidth={iconSize}
        onClick={() => searchInputRef.current.focus()}
      />
      <SearchInput
        $width={width}
        $backgroundColor={backgroundColor}
        $border={border}
        $padding={containerPadding}
        $iconWidth={iconSize}
        $fontColor={fontColor}
        $fontSize={fontSize}
        $lineHeight={lineHeight}
        $placeholderColor={placeholderColor}
        $placeholderWeight={placeholderWeight}
        type="text"
        value={searchText}
        onChange={(e) => onSearchTextChange(e.target.value)}
        placeholder={placeholder}
        ref={searchInputRef}
      />
    </SearchInputWrapper>
  );
};

GenericSearchBar.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.string,
  border: PropTypes.string,
  backgroundColor: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  placeholderColor: PropTypes.string,
  placeholderWeight: PropTypes.string,
  fontColor: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  containerPadding: PropTypes.string,
  searchText: PropTypes.string.isRequired,
  onSearchTextChange: PropTypes.func.isRequired,
};

export default GenericSearchBar;
