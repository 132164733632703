import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import FlexContainer from "../../components/common/_flexContainer";
import { useHistory } from "react-router-dom";
import axiosAdapter, { remToPx } from "../../utils";
import { env } from "../../env";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import loadingAnimation from "../../assets/images/Loading Animation.gif";
import NoNodes from "./components/NoNodes";
import PipelinesGraph from "./components/PipelinesGraph";
import { repositionNodes } from "../WorkSpaceConnectors/utils/getNodesAndEdges";

const NODE_HEIGHT = remToPx(10);
const NODE_WIDTH = remToPx(30);

const getCategoryColor = (category) => {
  switch (category) {
    case "Stream Connector":
      return "#6DC89B";
    case "Store Connector":
      return "#B5E3CE";
    case "Application":
      return "#EB6F95";
    case "Telematics Device":
      return "#F7C0D5";
    default:
      return "#EB6F95";
  }
};

const Main = styled(FlexContainer)`
  background: #0d0d0d;
  height: calc(100vh - 3.95rem);
  justify-content: ${({ $isLoading }) =>
    $isLoading ? "center" : "space-between"};
  align-content: ${({ $isLoading }) => ($isLoading ? "center" : "flex-start")};
  overflow: hidden;
  padding: ${({ $nodesPresent }) => ($nodesPresent ? "" : "2.777rem")};
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 1.111rem;
  line-height: 1.3;
  color: #f6f6f6;
  text-align: start;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 240px;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #2f2f2f;
  border-radius: 2px;
  box-shadow: -8px 4px 9px 0px #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 4px;
`;

const PipelinesPageNew = () => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);
  const [isGetWorkspaceLayoutLoading, setIsGetWorkspaceLayoutLoading] =
    useState(true);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [isNodeClickable, setIsNodeClickable] = useState(false);

  let content;

  if (isGetWorkspaceLayoutLoading) {
    content = (
      <AnimationContainer>
        <ScrollAnimationBar />
        <LoadingAnimation src={loadingAnimation} alt="Loading Animation" />
      </AnimationContainer>
    );
  } else if (nodes.length > 0) {
    content = (
      <PipelinesGraph
        nodes={nodes}
        edges={edges}
        isNodeClickable={isNodeClickable}
      />
    );
  } else {
    content = <NoNodes />;
  }

  // Get workspace layout
  useEffect(() => {
    const workspaceId = localStorage.getItem("selectedWorkspaceId");
    if (!workspaceId) {
      history.push("/");
    }
    (async () => {
      try {
        const getWorkspaceLayoutResponse = await axiosAdapter(
          "POST",
          `${env.REACT_APP_URL}/user-management/getWorkspaceLayout`,
          { workspaceId: parseInt(workspaceId) },
        );
        // Create a mapper to check if the edge already exists
        const tempMultipletargetMapper = new Map();
        const tempMultipleSourceMapper = new Map();
        const tempNodeCategoryMapper = new Map();
        for (const node of getWorkspaceLayoutResponse.data.data.layout.nodes) {
          tempNodeCategoryMapper.set(node.id, node.data.category);
        }
        for (const edge of getWorkspaceLayoutResponse.data.data.layout.edges) {
          if (tempMultipletargetMapper.has(edge.target)) {
            tempMultipletargetMapper.set(edge.target, true);
          } else {
            tempMultipletargetMapper.set(edge.target, false);
          }
          if (tempMultipleSourceMapper.has(edge.source)) {
            tempMultipleSourceMapper.set(edge.source, true);
          } else {
            tempMultipleSourceMapper.set(edge.source, false);
          }
        }
        const repositionedNodes = repositionNodes(
          getWorkspaceLayoutResponse.data.data.layout.nodes,
          getWorkspaceLayoutResponse.data.data.layout.edges,
          NODE_WIDTH,
          NODE_HEIGHT,
        );
        let highestX = NODE_WIDTH / 2;
        for (const node of repositionedNodes) {
          if (node.position.x > highestX) {
            highestX = node.position.x;
          }
        }
        setNodes(repositionedNodes);
        setEdges(
          getWorkspaceLayoutResponse.data.data.layout.edges.map((edge) => ({
            ...edge,
            data: {
              isCommonEdge:
                tempMultipletargetMapper.get(edge.target) ||
                tempMultipleSourceMapper.get(edge.source),
              edgeTypeColor: getCategoryColor(
                tempNodeCategoryMapper.get(edge.source),
              ),
            },
          })),
        );
        setIsNodeClickable(getWorkspaceLayoutResponse.data.data.isClickable);
        setIsGetWorkspaceLayoutLoading(false);
      } catch (err) {
        console.log("Error: getWorkspaceLayout", err);
        setIsGetWorkspaceLayoutLoading(false);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage(
            "Failed to authenticate, please go back and try again later !!!",
          );
        }
        setTimeout(() => {
          history.push("/applications");
        }, 1500);
      }
    })();
  }, [history, showMessage]);

  useEffect(() => {
    let nodeStatusInterval;
    if (nodes.length > 1) {
      nodeStatusInterval = setInterval(async () => {
        try {
          const configIds = nodes
            .filter((node) => node.type !== "BLANK")
            .map((node) => node.data.configId);
          const nodeStatus = await axiosAdapter(
            "POST",
            env.REACT_APP_URL + "pipeline/getNodeStatusNew",
            {
              ids: configIds,
              workspaceId: parseInt(
                localStorage.getItem("selectedWorkspaceId"),
              ),
            },
          );
          const statusMapper = new Map(
            nodeStatus.data.data.map((node) => [node.id, node.status]),
          );
          setNodes((prev) => {
            return prev.map((node) => ({
              ...node,
              data: {
                ...node.data,
                status: statusMapper.get(node.data.configId),
              },
            }));
          });
        } catch (err) {
          console.log("Error: getNodeStatusNew", err);
          if (err.response?.data?.remarks) {
            showMessage(err.response.data.remarks);
          } else {
            showMessage("Unable to obtain deployment statuses !!!");
          }
        }
      }, 10000);
    }
    return () => nodeStatusInterval && clearInterval(nodeStatusInterval);
  }, [nodes, showMessage]);

  return (
    <Main
      $isLoading={isGetWorkspaceLayoutLoading}
      $nodesPresent={nodes.length > 0}
      as="main"
    >
      {content}
    </Main>
  );
};

export default PipelinesPageNew;
