import styled from "styled-components";
import Column from "../../../components/common/_customColumn";
import GenericModal from "../../../components/common/_genericModal";
import { useCallback, useContext, useState } from "react";
import CustomRow from "../../../components/common/_customRow";
import GenericSelectionWithLabel from "../../../components/common/_genericSelectionWithLabel";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import axiosAdapter from "../../../utils";
import { env } from "../../../env";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../../components/common/_buttonNewOne";
import PropTypes from "prop-types";

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const Text = styled.p`
  margin: 0;
  color: #ececec;
  font-size: 1.25rem;
  line-height: 1.555rem;
`;

const InputFieldContainer = styled.div`
  flex-grow: 1;
  padding: 1.015rem;
  border-radius: 0.555rem;
  border: 0.069rem solid #b0b0b0;
  background-color: inherit;
`;

const InputField = styled.input`
  font-size: 0.972rem;
  font-weight: 500;
  line-height: 1.111rem;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  &::placeholder {
    color: #777777;
  }
`;

const createExpiryBufferObject = (value) => {
  if (value === 1) {
    return {
      name: "1 Day",
      value: 1,
    };
  } else {
    return {
      name: `${value} Days`,
      value: value,
    };
  }
};

const ApplicationEditDialog = ({
  dialogTitle,
  showEditDialog,
  onDialogClose,
  applicationNameProp = "",
  applicationExpiryBufferProp = 3,
  applicationId,
  refetchApplications,
}) => {
  // Hooks
  const { showMessage } = useContext(SnackbarContext);

  // State variables
  const [loading, setLoading] = useState(false);
  const [applicationName, setApplicationName] = useState(applicationNameProp);
  const [applicationExpiryBuffer, setApplicationExpiryBuffer] = useState(
    createExpiryBufferObject(applicationExpiryBufferProp),
  );

  // Callback functions
  const onEditClick = useCallback(
    async (appId, appName, expiryBuffer) => {
      setLoading(true);
      try {
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/editApplication",
          {
            applicationId: appId,
            applicationName: appName,
            expiryOffset: expiryBuffer.value,
          },
        );
        setLoading(false);
        refetchApplications();
        onDialogClose();
      } catch (err) {
        setLoading(false);
        console.log("Error: editApplication", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Unable to edit the Application");
        }
      }
    },
    [onDialogClose, refetchApplications, showMessage],
  );

  return (
    <GenericModal
      title={dialogTitle}
      show={showEditDialog}
      onClose={onDialogClose}
      disableCloseOnOutsideClick={loading}
    >
      <CustomRow>
        <CustomColumn mb={1.666}>
          <Text>
            You can edit the Application name and choose how long the container
            stays active.{" "}
          </Text>
        </CustomColumn>
        <CustomColumn style={{ display: "flex", gap: "1.111rem" }} mb={3.194}>
          <InputFieldContainer>
            <InputField
              onChange={(e) => setApplicationName(e.target.value)}
              value={applicationName}
              placeholder="Application Name"
            />
          </InputFieldContainer>
          <div style={{ flexGrow: 1 }}>
            <GenericSelectionWithLabel
              selectedOption={applicationExpiryBuffer}
              selectionOptions={[
                {
                  name: "1 Day",
                  value: 1,
                },
                {
                  name: "2 Days",
                  value: 2,
                },
                {
                  name: "3 Days",
                  value: 3,
                },
                {
                  name: "4 Days",
                  value: 4,
                },
                {
                  name: "5 Days",
                  value: 5,
                },
                {
                  name: "6 Days",
                  value: 6,
                },
                {
                  name: "7 Days",
                  value: 7,
                },
              ]}
              onSelectionChange={(value) => {
                setApplicationExpiryBuffer(value);
              }}
              selectionNameFunction={(option) => option.name}
              dropdownWidth="100%"
              containerPadding="1.111rem"
              selectionLineHeight="1.25rem"
            />
          </div>
        </CustomColumn>
        <Column style={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledButtonsecondary
            style={{ width: "14.167rem", marginRight: "1.112rem" }}
            onClick={onDialogClose}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledButtonPrimary
            style={{ width: "14.167rem" }}
            onClick={() =>
              onEditClick(
                applicationId,
                applicationName,
                applicationExpiryBuffer,
              )
            }
          >
            Edit Application
          </StyledButtonPrimary>
        </Column>
      </CustomRow>
    </GenericModal>
  );
};

ApplicationEditDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  showEditDialog: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  applicationNameProp: PropTypes.string,
  applicationExpiryBufferProp: PropTypes.number,
  applicationId: PropTypes.string.isRequired,
  refetchApplications: PropTypes.func.isRequired,
};

export default ApplicationEditDialog;
