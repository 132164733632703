import React from "react";
import FlexContainer from "../../../../components/common/_flexContainer";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "../../../../assets/images/workspace_settings_edit_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/workspace_settings_delete_icon.svg";
import IconButton from "../../../../components/common/_iconButton";
import PropTypes from "prop-types"; // Import PropTypes

const EditIconStyled = styled(EditIcon)`
  width: 1.666rem;
  height: 1.666rem;
`;
const DeleteIconStyled = styled(DeleteIcon)`
  width: 1.666rem;
  height: 1.666rem;
`;

const WorkspaceEditDeleteCell = ({
  row,
  onEditButtonClick,
  onDeleteButtonClick,
  isOperationAllowed,
}) => {
  return (
    <FlexContainer $width="100%" $justifyContent="flex-end" $gap="1.111rem">
      <IconButton
        onClick={() => onEditButtonClick(row)}
        disabled={!isOperationAllowed}
      >
        <EditIconStyled />
      </IconButton>
      <IconButton
        disabled={!isOperationAllowed}
        $hoverIconColor={"#EA3A3A"}
        onClick={() => onDeleteButtonClick(row)}
      >
        <DeleteIconStyled />
      </IconButton>
    </FlexContainer>
  );
};

WorkspaceEditDeleteCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
  isOperationAllowed: PropTypes.bool.isRequired,
};

export default WorkspaceEditDeleteCell;
