import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DevContainerLifecycleSVG } from "../../../assets/images/dev_container_lifecycle.svg";
import FlexContainer from "../../../components/common/_flexContainer";
import Text from "../../../components/common/_text";
import { ReactComponent as DeployInitIcon } from "../../../assets/images/deploy_init.svg";
import { ReactComponent as DeploySuccessIcon } from "../../../assets/images/deploy_success.svg";
import { ReactComponent as DeployFailIcon } from "../../../assets/images/deploy_fail.svg";
import Slider from "../../../components/common/_slider";
import { StyledButtonPrimary } from "../../../components/common/_buttonNewOne";
import { useHistory } from "react-router-dom";
import axiosAdapter from "../../../utils";
import { env } from "../../../env";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import PropTypes from "prop-types";

const Main = styled.main`
  background: #0d0d0d;
  height: calc(100vh - 3.95rem);
  overflow: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 1.111rem;
  line-height: 1.3;
  color: #f6f6f6;
  text-align: start;
`;

const DevContainerLifecycleSVGStyled = styled(DevContainerLifecycleSVG)`
  height: 100%;
`;
const getStatusColor = ($status) => {
  if ($status === "SUCCESS") {
    return "#C6EFF2";
  } else if ($status === "FAILURE") {
    return "#F6F6F6";
  } else {
    return "#777777";
  }
};

const getBackgroundColor = ($status) => {
  if ($status === "SUCCESS") {
    return "#47CCD6";
  } else if ($status === "FAILURE") {
    return "#EA3A3A";
  } else {
    return "#2F2F2F";
  }
};

const StatusIconContainer = styled(FlexContainer)`
  place-content: center;
  width: 2.222rem;
  height: 2.222rem;
  border: 0.0694rem solid ${({ $status }) => getStatusColor($status)};
  border-radius: 0.555rem;
  background-color: ${({ $status }) => getBackgroundColor($status)};
`;

const DeployIconStyled = styled(DeployInitIcon)`
  width: 1.388rem;
  height: 1.388rem;
  & path {
    fill: ${({ $status }) =>
      $status === "SUCCESS" || $status === "FAILURE" ? "#F6F6F6" : "#777777"};
  }
`;

const SliderContainer = styled(FlexContainer)`
  width: 100%;
  justify-content: flex-start;
  margin: 0rem 0rem 5.555rem 0;
`;

const PrimaryButtonStyled = styled(StyledButtonPrimary)`
  width: 22.5rem;
`;
const DevContainerLifecycle = ({
  createAppResponse,
  handleRetry,
  isDeployConnector,
}) => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);
  const [expirationOffset, setExpirationOffset] = useState(3);

  const handleOnConnectorDeployment = useCallback(() => {
    history.push("/connectorsPlayground", {
      selectedWorkspace: localStorage.getItem("selectedWorkSpaceId"),
    });
  }, [history]);

  const handleUpdateExpiryOfApplication = useCallback(async () => {
    if (!createAppResponse || createAppResponse.code !== 200) {
      showMessage("Failed to create application !!!");
      return;
    }

    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/updateApplicationExpiry",
        {
          applicationId: createAppResponse.data.id,
          expiryOffset: expirationOffset,
        },
      );

      history.push("/repoInformation", {
        isRedirected: true,
        applicationId: createAppResponse.data.id,
      });
    } catch (err) {
      console.log("Error: updateApplicationExpiry", err);
      const errorMessage =
        err.response?.data?.remarks ||
        "Failed to update the application expiry !!!";
      showMessage(errorMessage);
    }
  }, [createAppResponse, expirationOffset, history, showMessage]);

  const getDeploymentStatus = () => {
    if (createAppResponse?.code === 200) return "SUCCESS";
    if (createAppResponse?.code === 500) return "FAILURE";
    return "IN_PROGRESS";
  };

  const getDeploymentIcon = () => {
    return createAppResponse?.code === 500 ? DeployFailIcon : DeploySuccessIcon;
  };

  const getActionButton = () => {
    const isSuccess = createAppResponse?.code === 200;

    let onClick;
    if (!isSuccess) {
      onClick = handleRetry;
    } else {
      onClick = isDeployConnector
        ? handleOnConnectorDeployment
        : handleUpdateExpiryOfApplication;
    }

    const buttonLabel = !createAppResponse || isSuccess ? "Next" : "Retry";

    return (
      <PrimaryButtonStyled disabled={!createAppResponse} onClick={onClick}>
        {buttonLabel}
      </PrimaryButtonStyled>
    );
  };

  return (
    <Main>
      <DevContainerLifecycleSVGStyled />
      <FlexContainer
        $height="100%"
        $flex="1 0"
        $padding="0rem 3.777rem 0rem 3.777rem"
        $justifyContent="flex-start"
        $alignContent="center"
      >
        <Text $fontWeight="bold" $fontSize="1.944rem" $margin="0 0 1.111rem 0">
          {`Deploying Your ${isDeployConnector ? "Connector" : "Application"}`}
        </Text>
        <Text $margin="0 0 1.666rem 0" style={{ opacity: 0.7 }}>
          {isDeployConnector
            ? "This will take a little while – we're setting up everything for you. You'll see your connector live on the pipeline soon, ready to work"
            : "Hang tight – we're setting things up for you! Your application will be live on the Applications page shortly and ready to go."}
        </Text>
        <FlexContainer
          $width="100%"
          $margin="0 0 5.555rem 0"
          $justifyContent="flex-start"
          $gap="0rem 0.833rem"
          $textAlign="justify"
        >
          <StatusIconContainer $status="SUCCESS">
            <DeployIconStyled $status="SUCCESS" as={DeployInitIcon} />
          </StatusIconContainer>
          <Text
            $width="calc(100% - 2.222rem - 0.834rem)"
            $fontWeight="bold"
            $fontSize="1.25rem"
          >
            Deployment Initialised
          </Text>
          <FlexContainer
            $width="2.222rem"
            $height="1.111rem"
            $justifyContent="center"
          >
            <div
              style={{
                width: "0.0694rem",
                height: "100%",
                background:
                  "linear-gradient(180deg, rgba(71, 204, 214, 0) 7%, #47CCD6 100%)",
              }}
            />
          </FlexContainer>
          <FlexContainer $width="calc(100% - 2.222rem - 0.834rem)"></FlexContainer>
          <StatusIconContainer $status={getDeploymentStatus()}>
            <DeployIconStyled
              $status={getDeploymentStatus()}
              as={getDeploymentIcon()}
            />
          </StatusIconContainer>
          <Text
            $width="calc(100% - 2.222rem - 0.834rem)"
            $fontWeight="bold"
            $fontSize="1.25rem"
          >
            {createAppResponse?.code === 500
              ? "Deployment Failed"
              : "Deployment Successful"}
          </Text>
        </FlexContainer>
        {(createAppResponse?.code === 200 || !createAppResponse) &&
          !isDeployConnector && (
            <>
              <Text
                $fontWeight="bold"
                $fontSize="1.944rem"
                $margin="0 0 1.111rem 0"
              >
                Development Environment Expiry
              </Text>
              <Text $margin="0 0 1.666rem 0" style={{ opacity: 0.7 }}>
                Please choose how long the container stays active. You can
                revisit this page to reset the expiration before it ends
              </Text>
              <SliderContainer>
                <Slider
                  minValue={1}
                  maxValue={7}
                  markInterval={1}
                  sliderValue={expirationOffset}
                  setSliderValue={setExpirationOffset}
                  markLabelFormatterFunction={(value) =>
                    `${value} ${value === 1 ? "Day" : "Days"}`
                  }
                />
              </SliderContainer>
            </>
          )}
        <FlexContainer $width="100%" $justifyContent="flex-end">
          {getActionButton()}
        </FlexContainer>
      </FlexContainer>
    </Main>
  );
};

DevContainerLifecycle.propTypes = {
  createAppResponse: PropTypes.shape({
    code: PropTypes.number,
    data: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  handleRetry: PropTypes.func.isRequired,
  isDeployConnector: PropTypes.bool.isRequired,
};

export default DevContainerLifecycle;
