import React, { Fragment } from "react";
import FlexContainer from "../../../../components/common/_flexContainer";
import Chip from "../../../../components/common/_chip";
import Text from "../../../../components/common/_text";
import Tooltip from "../../../../components/common/_tooltip";
import Popover from "../../../../components/common/_popover";
import styled from "styled-components";
import { ReactComponent as AddWorkspaceMemberIcon } from "../../../../assets/images/add_workspace_member.svg";
import PropTypes from "prop-types"; // Import PropTypes

const AddWorkspaceMemberIconStyled = styled(AddWorkspaceMemberIcon)`
  width: 1.111rem;
  height: 1.111rem;
`;

const WorkspaceMembersCell = ({ row, onEditWorkspaceMembersClick }) => {
  const PopoverContent = row.workspaceMembers
    .slice(3)
    .map((workspaceMember) => (
      <Fragment key={workspaceMember}>
        <Chip
          $width="1.388rem"
          $height="1.388rem"
          $background="#B0B0B0"
          $border="none"
        >
          <Text
            $fontWeight="bold"
            $fontSize="0.833rem"
            $color="#4B4B4B"
            $cursor="default"
          >
            {workspaceMember.substring(0, 1).toUpperCase()}
          </Text>
        </Chip>
        <Text $width="calc(100% - 1.388rem - 0.555rem)" $fontSize="0.833rem">
          {workspaceMember}
        </Text>
      </Fragment>
    ));

  return (
    <FlexContainer
      $width="100%"
      $justifyContent="flex-start"
      $flexWrap="nowrap"
    >
      {row.workspaceMembers.map((workspaceMember, index) =>
        index <= 3 ? (
          index < 3 ? (
            <Tooltip
              key={workspaceMember}
              title={workspaceMember}
              placement="bottom"
            >
              <Chip $index={index}>
                <Text $fontWeight="bold" $fontSize="0.833rem" $cursor="default">
                  {workspaceMember.substring(0, 1).toUpperCase()}
                </Text>
              </Chip>
            </Tooltip>
          ) : (
            <Popover
              $maxWidth="10.416rem"
              key={workspaceMember}
              content={PopoverContent}
              placement="bottom-start"
            >
              <Chip $index={index} $hoverBorder="0.069rem solid #B0B0B0">
                <Text $fontWeight="bold" $fontSize="0.833rem" $cursor="default">
                  {`+${row.workspaceMembers.length - 3}`}
                </Text>
              </Chip>
            </Popover>
          )
        ) : null,
      )}
      {row.isEditable && (
        <Chip
          $index={
            row.workspaceMembers.length <= 3 ? row.workspaceMembers.length : 4
          }
          $cursor="pointer"
          onClick={() => onEditWorkspaceMembersClick(row)}
        >
          <AddWorkspaceMemberIconStyled />
        </Chip>
      )}
    </FlexContainer>
  );
};

WorkspaceMembersCell.propTypes = {
  row: PropTypes.shape({
    workspaceMembers: PropTypes.arrayOf(PropTypes.string).isRequired,
    isEditable: PropTypes.bool.isRequired,
  }).isRequired,
  onEditWorkspaceMembersClick: PropTypes.func.isRequired,
};

export default WorkspaceMembersCell;
