import { React, useState, useEffect, useContext, useRef } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { env } from "../../env";
import { io } from "socket.io-client";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import ExpandIcon from "../../assets/images/expand_button.svg";
import MinimiseIcon from "../../assets/images/Minimise.svg";
import ConfigLogIcon from "../../assets/images/connector_logs.svg";
import Column from "../../components/common/_customColumn";
import CancelIcon from "../../assets/images/cancel_icon.svg";
// import DownloadIcon from "../../assets/images/download_button.png";
import PlayIcon from "../../assets/images/play_button.svg";
import PauseIcon from "../../assets/images/pause_button.svg";
import BackIcon from "../../assets/images/Back_Arrow.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomRow from "../common/_customRow";
import PropTypes from "prop-types";

const ConnectorLog = styled.div`
  height: 100%;
  background-color: #2f2f2f;
  padding: 1.112rem;
  border-radius: 0.84rem;
  overflow-y: scroll;
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.39rem;
  color: #fafafa;
  padding-left: 0.84rem;
  font-weight: bold;
`;

const TimeUnitArea = styled.div`
  width: 38%;
  background-color: #4b4b4b;
  border: none;
  border-radius: 0.208rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.277rem;
`;
const TimeValue = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 0.84rem;
  width: 100%;
  background: none;
  border: none;
  color: #fafafa;
  outline: none;
  text-align: center;
  &::placeholder {
    color: #fafafa;
  }
`;
const MinIcon = styled.img`
  height: 2.225rem;
  width: 2.225rem;
  cursor: pointer;
`;
const LogIcon = styled.img`
  height: 2.225rem;
  width: 2.225rem;
`;
const TimeUnit = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 0.84rem;
  font-weight: bold;
  color: #fafafa;
  padding-left: 0.55rem;
  padding-top: 0.277rem;
  letter-spacing: 0;
`;

const ButtonArea = styled.div`
  border: 0.069rem solid var(--black-2, #2f2f2f);
  background-color: #5e5e5e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem 0.55rem;
  cursor: pointer;
`;
const ButtonIcon = styled.img`
  height: 1.112rem;
  width: 1.112rem;
`;
const ExpandLogs = styled.div`
  height: calc(100vh - 3.89rem);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #0d0d0d;
  border: 0.069rem solid var(--grey-1, #777777);
  border-radius: 1.67rem 1.67rem 0px 0px;
  padding: 1.112rem;
  margin-top: 5rem;
  margin-left: 1.112rem;
  margin-right: 1.112rem;
  overflow-y: scroll;
`;

const Text = styled.p`
  color: #f6f6f6;
  margin: 0;
`;
const ConnectorLogs = ({ isLogsExpanded, setIsLogsExpanded, nodeData }) => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);
  const [logRepeatTime, setLogRepeatTime] = useState(5);
  const [isPlaying, setIsPlaying] = useState(false);
  const [podLogs, setPodLogs] = useState([]);
  const logSocket = useRef(null);

  useEffect(() => {
    logSocket.current = io(env.REACT_APP_SOCKET_IO_URL, {
      auth: {
        token: localStorage.getItem("token"),
      },
      path: "/deployment-logs",
    });

    logSocket.current.on("deploymentLogs", (message) => {
      setPodLogs((prev) => {
        return [
          ...prev,
          ...(message ? message.split("\n").filter((x) => x) : []),
        ];
      });
    });

    logSocket.current.on("deploymentLogsResponse", (data, callback) => {
      if (data.code !== 200) {
        if (typeof callback === "function") callback();
        setIsPlaying(false);
        showMessage(data.message, "error");
      }
    });

    logSocket.current.on("disconnect", () => {
      setIsPlaying(false);
    });

    logSocket.current.on("logs", (message) => {
      setPodLogs((prev) => {
        return [
          ...prev,
          ...(message ? message.split("\n").filter((x) => x) : []),
        ];
      });
    });

    logSocket.current.on("disconnect", () => {
      setIsPlaying(false);
    });

    return () => {
      if (logSocket.current) {
        logSocket.current.off("deploymentLogs");
        logSocket.current.off("deploymentLogsResponse");
        logSocket.current.off("disconnect");
        logSocket.current.disconnect();
        logSocket.current = null;
      }
    };
  }, [showMessage]);

  useEffect(() => {
    if (isPlaying) {
      if (logSocket.current) {
        if (!logSocket.current.active) {
          logSocket.current.connect();
        }

        logSocket.current.emit("/startDeploymentLogs", {
          configId: nodeData.configId,
          workspaceId: parseInt(localStorage.getItem("selectedWorkspaceId")),
          duration: parseInt(logRepeatTime),
        });
      }
    } else {
      // Disconnect or perform cleanup when not playing
      if (logSocket.current?.connected) {
        logSocket.current.emit("/stopDeploymentLogs");
        logSocket.current.disconnect(); // Explicitly disconnect the socket
      }
    }
  }, [isPlaying, logRepeatTime, nodeData.configId]);

  const handlePlayPause = () => {
    if (!isPlaying) {
      if (parseInt(logRepeatTime) >= 1 && parseInt(logRepeatTime) <= 1000) {
        setIsPlaying(true);
      } else {
        showMessage("Please enter a value between 1 and 1000", "error");
      }
    } else {
      setIsPlaying(false);
    }
  };

  const handleStop = () => {
    logSocket.current.disconnect();
    setPodLogs([]);
  };

  const toggleExpandLogs = () => {
    setIsLogsExpanded(!isLogsExpanded);
  };
  const handleBackIcon = () => {
    history.push("/connectorsPlayground");
  };
  return isLogsExpanded ? (
    <ExpandLogs>
      <CustomRow>
        <Col xs={11} style={{ display: "flex" }}>
          <div style={{ paddingTop: "0.277rem" }}>
            <LogIcon
              src={BackIcon}
              alt=""
              onClick={handleBackIcon}
              style={{ cursor: "pointer", width: "1.4rem", height: "0.972rem" }}
            />
          </div>
          <Title
            style={{ paddingLeft: "1.112rem" }}
          >{`Logs for ${nodeData.title} `}</Title>
        </Col>
        <Col
          xs={1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <MinIcon src={MinimiseIcon} alt="img" onClick={toggleExpandLogs} />
        </Col>
      </CustomRow>
      <CustomRow style={{ paddingTop: "1.112rem", rowGap: "0.55rem" }}>
        {podLogs.map((log) => (
          <Column xs={12} key={log.id || log}>
            <Text>{log}</Text>
          </Column>
        ))}
      </CustomRow>
    </ExpandLogs>
  ) : (
    <ConnectorLog>
      <CustomRow>
        <Column
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          style={{ display: "flex" }}
        >
          <LogIcon src={ConfigLogIcon} alt="" />
          <Title>{`Logs for ${nodeData.title} `}</Title>
        </Column>
        <Column xs={12} sm={12} md={4} lg={4} xl={4}>
          <CustomRow>
            <Column
              xs={5}
              style={{
                display: "flex",
                backgroundColor: "#5e5e5e",
                border: "0.069rem solid #2f2f2f",
                padding: "0.347rem 0.55rem",
              }}
            >
              <TimeUnitArea>
                <TimeValue
                  value={logRepeatTime}
                  onChange={(e) => {
                    if (/^\d*$/.test(e.target.value)) {
                      setLogRepeatTime(e.target.value);
                      handleStop();
                    }
                  }}
                />
              </TimeUnitArea>
              <TimeUnit>Seconds</TimeUnit>
            </Column>
            <Column xs={2.27}>
              <ButtonArea
                onClick={() => {
                  handlePlayPause();
                }}
              >
                <ButtonIcon src={isPlaying ? PauseIcon : PlayIcon} alt="" />
              </ButtonArea>
            </Column>
            <Column xs={2.27}>
              <ButtonArea onClick={() => handleStop()}>
                <ButtonIcon src={CancelIcon} alt="Clear" />
              </ButtonArea>
            </Column>
            <Column xs={2.27}>
              <ButtonArea onClick={toggleExpandLogs}>
                <ButtonIcon src={ExpandIcon} alt="Expand" />
              </ButtonArea>
            </Column>
          </CustomRow>
        </Column>
      </CustomRow>
      <CustomRow style={{ paddingTop: "1.112rem", rowGap: "0.55rem" }}>
        {podLogs.map((log) => (
          <Column xs={12} key={log.id || log}>
            <Text
              style={{
                fontFamily: "'Fira Code', Consolas, 'Courier New', monospace",
              }}
            >
              {log}
            </Text>
          </Column>
        ))}
      </CustomRow>
    </ConnectorLog>
  );
};

ConnectorLogs.propTypes = {
  isLogsExpanded: PropTypes.bool.isRequired,
  setIsLogsExpanded: PropTypes.func.isRequired,
  nodeData: PropTypes.shape({
    configId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default ConnectorLogs;
