import styled from "styled-components";
import Column from "../../components/common/_customColumn";
import CustomRow from "../../components/common/_customRow";
import { CommonFirstPage } from "../LandingPages/CommonFirstPage";
import { useState, useContext } from "react";
import ShowPasswordIcon from "../../assets/images/show_password_icon.svg";
import DontShowPasswordIcon from "../../assets/images/dont_show_password_icon.svg";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../components/common/_buttonNewOne";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { AuthContext } from "../../context/AuthContext";
import { env } from "../../env";

const USERNAME_REGEX = /^[a-z0-9._]{6,250}$/;

const PASSWORD_REGEX =
  /^(?!.*\\)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_+=[\]{}|;:,.<>?/~]).{8,32}$/;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  color: #f6f6f6;
  font-size: 1.527rem;
`;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #2f2f2f;
`;

export const SignIn = () => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);
  const { login } = useContext(AuthContext);

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [disableAllButtons, setDisableAllButtons] = useState(false);

  const handleLoginClick = async () => {
    setDisableAllButtons(true);
    setUsernameError("");
    setPasswordError("");
    let isUsernameValid = true;
    let isPasswordValid = true;

    // Username validations
    if (!username.trim()) {
      setUsernameError("Please enter a value for the username");
      isUsernameValid = false;
    }

    if (isUsernameValid && !USERNAME_REGEX.test(username)) {
      setUsernameError(
        "Username must be between 6 and 250 characters and can include only include a-z, 0-9, . and _",
      );
      isUsernameValid = false;
    }

    // Password validations
    if (!password.length) {
      setPasswordError("Please enter a value for the password");
      isPasswordValid = false;
    }

    if (isPasswordValid && !PASSWORD_REGEX.test(password)) {
      setPasswordError(
        `Password must be between 8 and 32 characters and must include at least one uppercase letter, one lowercase letter, one number and one special character among !@#$%^&*()-_+={}[]|;:,.<>?/~`,
      );
      isPasswordValid = false;
    }

    if (!isUsernameValid || !isPasswordValid) {
      setDisableAllButtons(false);
      return;
    }

    try {
      const loginResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/userLogin",
        {
          username,
          password,
        },
      );
      showMessage("Login Successful", "success");

      login(loginResponse.data.data.token);
      setDisableAllButtons(false);
    } catch (err) {
      console.log("Error: userLogin", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to login, please try again later");
      }
      setDisableAllButtons(false);
    }
  };

  return (
    <CommonFirstPage>
      <CustomRow>
        <CustomColumn mb={2.777}>
          <Text style={{ letterSpacing: "0" }}>
            Welcome Back! Login to Continue
          </Text>
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <InputFieldContainer
            style={
              usernameError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Username"
              name="username"
              onChange={(e) => setUsername(e.target.value.trim().toLowerCase())}
              value={username}
            />
          </InputFieldContainer>
          {usernameError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {usernameError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={0.833}>
          <InputFieldContainer
            style={
              passwordError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type={showPassword ? "text" : "password"}
            />
            <button
              type="button"
              aria-label={showPassword ? "Hide Password" : "Show Password"}
              onClick={() => setShowPassword(!showPassword)}
              onKeyDown={(e) => {
                // Allow interaction via "Enter" or "Space" key for accessibility
                if (e.key === "Enter" || e.key === " ") {
                  setShowPassword(!showPassword);
                }
              }}
              style={{
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
                marginLeft: "0.347rem",
                height: "1.25rem",
                width: "1.25rem",
              }}
            >
              <img
                src={showPassword ? DontShowPasswordIcon : ShowPasswordIcon}
                alt={showPassword ? "Hide Password" : "Show Password"}
                style={{ height: "1.25rem", width: "1.25rem" }}
              />
            </button>
          </InputFieldContainer>
          {passwordError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {passwordError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={2.222}>
          <Text
            style={{
              fontSize: "1.111rem",
              fontWeight: "bold",
              color: "#CCCCCC",
              textDecoration: "underline",
              lineHeight: "1.444rem",
              cursor: "pointer",
            }}
            onClick={() =>
              !disableAllButtons && history.push("/forgotUsernamePassword")
            }
          >
            Forgot Username / Password?
          </Text>
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <StyledButtonPrimary
            style={{ width: "100%", height: "3.888rem" }}
            onClick={handleLoginClick}
            disabled={disableAllButtons}
          >
            Login
          </StyledButtonPrimary>
        </CustomColumn>
        <CustomColumn mb={1.111} style={{ textAlign: "center" }}>
          <Text>or</Text>
        </CustomColumn>
        <CustomColumn>
          <StyledButtonsecondary
            style={{ width: "100%", height: "3.888rem" }}
            onClick={() => history.push("/signUp")}
            disabled={disableAllButtons}
          >
            Signup
          </StyledButtonsecondary>
        </CustomColumn>
      </CustomRow>
    </CommonFirstPage>
  );
};
