import React from "react";
import FlexContainer from "../../../../components/common/_flexContainer";
import styled from "styled-components";
import Text from "../../../../components/common/_text";
import Tooltip from "../../../../components/common/_tooltip";
import PropTypes from "prop-types";

const SquareChip = styled(FlexContainer)`
  width: 2.777rem;
  height: 2.777rem;
  background: #2f2f2f;
  border: 0.069rem solid #4b4b4b;
  border-radius: 0.555rem;
`;
const FlexItem = styled.div`
  width: 100%;
`;

const WorkspaceMemberDetailCell = ({ row }) => {
  return (
    <FlexContainer $width="100%" $justifyContent="flex-start" $gap="0.833rem">
      <SquareChip $justifyContent="center" $alignContent="center">
        <Text $fontWeight="bold" $fontSize="1.666rem" $color="#777777">
          {row.fullName.substring(0, 1).toUpperCase()}
        </Text>
      </SquareChip>
      <FlexContainer $width="calc(100% - 2.777rem - 0.833rem)" $gap="0.277rem">
        <FlexItem>
          <Tooltip title={row.fullName}>
            <Text $maxWidth="min-content" $fontWeight="bold" $noWrap>
              {row.fullName}
            </Text>
          </Tooltip>
        </FlexItem>
        <FlexItem>
          <Tooltip title={row.email}>
            <Text
              $maxWidth="min-content"
              $fontSize="0.833rem"
              $color="#777777"
              $noWrap
            >
              {row.email}
            </Text>
          </Tooltip>
        </FlexItem>
      </FlexContainer>
    </FlexContainer>
  );
};

WorkspaceMemberDetailCell.propTypes = {
  row: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default WorkspaceMemberDetailCell;
