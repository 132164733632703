import React, { Fragment } from "react";
import Chip from "../common/_chip";
import Text from "../common/_text";
import Tooltip from "../common/_tooltip";
import Popover from "../common/_popover";
import FlexContainer from "../common/_flexContainer";
import PropTypes from "prop-types";

const WorkspaceCardMembers = ({ row }) => {
  const PopoverContent = row.workspaceMembers
    .slice(3)
    .map((workspaceMember) => (
      <Fragment key={workspaceMember}>
        <Chip
          $width="1.388rem"
          $height="1.388rem"
          $background="#B0B0B0"
          $border="none"
        >
          <Text
            $fontWeight="bold"
            $fontSize="0.833rem"
            $color="#4B4B4B"
            $cursor="default"
          >
            {workspaceMember.substring(0, 1).toUpperCase()}
          </Text>
        </Chip>
        <Text $width="calc(100% - 1.388rem - 0.555rem)" $fontSize="0.833rem">
          {workspaceMember}
        </Text>
      </Fragment>
    ));

  return (
    <FlexContainer
      $width="100%"
      $justifyContent="flex-start"
      $flexWrap="nowrap"
    >
      {row.workspaceMembers.map((workspaceMember, index) => {
        let chipContent;

        if (index <= 3) {
          if (index < 3) {
            chipContent = (
              <Tooltip
                key={workspaceMember}
                title={workspaceMember}
                placement="bottom"
              >
                <Chip
                  $index={index}
                  $background="#B0B0B0"
                  $border="0.138rem solid #2F2F2F"
                >
                  <Text
                    $fontWeight="bold"
                    $fontSize="0.833rem"
                    $color="#4B4B4B"
                    $cursor="default"
                  >
                    {workspaceMember.substring(0, 1).toUpperCase()}
                  </Text>
                </Chip>
              </Tooltip>
            );
          } else {
            chipContent = (
              <Popover
                $maxWidth="10.416rem"
                key={`more-${row.workspaceMembers.length}`}
                content={PopoverContent}
                placement="bottom-end"
              >
                <Chip
                  $index={index}
                  $background="#B0B0B0"
                  $border="0.138rem solid #2F2F2F"
                >
                  <Text
                    $fontWeight="bold"
                    $fontSize="0.833rem"
                    $color="#4B4B4B"
                    $cursor="default"
                  >
                    {`+${row.workspaceMembers.length - 3}`}
                  </Text>
                </Chip>
              </Popover>
            );
          }

          return chipContent;
        } else {
          return null;
        }
      })}
    </FlexContainer>
  );
};

WorkspaceCardMembers.propTypes = {
  row: PropTypes.shape({
    workspaceMembers: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};
export default WorkspaceCardMembers;
