import React from "react";
import FlexContainer from "../../../../components/common/_flexContainer";
import Text from "../../../../components/common/_text";
import moment from "moment/moment";
import PropTypes from "prop-types"; // Import PropTypes

const WorkspaceCreatedOnCell = ({ row }) => {
  return (
    <FlexContainer $width="100%" $gap="0.277rem">
      <Text $width="100%" $fontSize="0.833rem" $color="#B0B0B0">
        Created On
      </Text>
      <Text $width="100%" $fontWeight="bold">
        {moment.unix(parseInt(row.createdOn)).format("D MMMM, YYYY")}
      </Text>
    </FlexContainer>
  );
};

WorkspaceCreatedOnCell.propTypes = {
  row: PropTypes.shape({
    createdOn: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  }).isRequired,
};

export default WorkspaceCreatedOnCell;
