import { useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.277rem;
`;

const ProgressCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 0.277rem;
  border-radius: 0.277rem;
  background-color: ${({ progressBarBgColor }) => progressBarBgColor};
`;

const ProgressBar = styled.div`
  height: 100%;
  background-color: ${({ $progressBarColor }) => $progressBarColor};
  border-radius: 0.277rem;
  width: ${({ $progressPercentage }) => $progressPercentage}%;
`;

const Text = styled.p`
  margin: 0;
  font-family: "Articulat CF Medium";
  font-size: 0.972rem;
  color: ${({ $fontColor }) => $fontColor || "#F6F6F6"};
`;

/**
 * GenericProgressBar component
 *
 * @param {string} [progressBarColor="#47CCD6"] - The color of the progress bar
 * @param {string} [progressBarBgColor="#4B4B4B"] - The background color of the progress bar
 * @param {string} [progressBarTextColor="#B0B0B0"] - The color of the text describing the progress
 * @param {string} [progressCountColor="#F6F6F6"] - The color of the text describing the progress count
 * @param {string} progressText - The text describing the progress
 * @param {number} progressCount - The count of progress
 * @param {number} progressTotal - The total count of progress
 * @param {boolean} [showProgressCount] - Whether to show the progress count, if true, the progress ratio and progressText will be displayed. Hence those values need to be passed
 */
const GenericProgressBar = ({
  progressBarColor = "#47CCD6",
  progressBarBgColor = "#4B4B4B",
  progressBarTextColor = "#B0B0B0",
  progressCountColor = "#F6F6F6",
  progressText,
  progressCount,
  progressTotal,
  showProgressCount,
}) => {
  const progressPercentage = useMemo(() => {
    return Math.floor((progressCount / progressTotal) * 100);
  }, [progressCount, progressTotal]);

  return (
    <Container>
      {showProgressCount && (
        <ProgressCountContainer>
          <Text
            $fontColor={progressBarTextColor}
          >{`${progressPercentage}% ${progressText}`}</Text>
          <Text
            $fontColor={progressCountColor}
          >{`${progressCount}/${progressTotal}`}</Text>
        </ProgressCountContainer>
      )}
      <ProgressBarContainer progressBarBgColor={progressBarBgColor}>
        <ProgressBar
          $progressBarColor={progressBarColor}
          $progressPercentage={progressPercentage}
        />
      </ProgressBarContainer>
    </Container>
  );
};

GenericProgressBar.propTypes = {
  progressBarColor: PropTypes.string,
  progressBarBgColor: PropTypes.string,
  progressBarTextColor: PropTypes.string,
  progressCountColor: PropTypes.string,
  progressText: PropTypes.string.isRequired,
  progressCount: PropTypes.number.isRequired,
  progressTotal: PropTypes.number.isRequired,
  showProgressCount: PropTypes.bool,
};

export default GenericProgressBar;
