import styled from "styled-components";
import Column from "../../components/common/_customColumn";
import CustomRow from "../../components/common/_customRow";
import { CommonFirstPage } from "../LandingPages/CommonFirstPage";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  color: #f6f6f6;
  font-size: 1.527rem;
  line-height: 2.166rem;
`;

const ForgotContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $isSelected }) => ($isSelected ? "pointer" : "not-allowed")};
  font-size: 1.25rem;
  line-height: 1.666rem;
  font-weight: ${({ $isSelected }) => ($isSelected ? "bold" : "500")};
  color: ${({ $isSelected }) => ($isSelected ? "#47CCD6" : "#777777")};
  padding-bottom: ${({ $isSelected }) =>
    $isSelected ? "0.625rem" : "0.694rem"};
  border-bottom: ${({ $isSelected }) =>
    $isSelected ? "0.138rem solid #47CCD6" : "0.069rem solid #4B4B4B"};
  border-bottom-left-radius: ${({ $isSelected }) =>
    $isSelected ? "0.138rem" : "0"};
  border-bottom-right-radius: ${({ $isSelected }) =>
    $isSelected ? "0.138rem" : "0"};
`;

export const ForgotUsername = () => {
  const history = useHistory();
  const location = useLocation();

  if (!location.state?.isRedirected) {
    history.push("/");
  }

  return (
    <CommonFirstPage>
      <CustomRow>
        <CustomColumn mb={2.083} style={{ display: "flex" }}>
          <ForgotContainer $isSelected={true}>Forgot Username</ForgotContainer>
          <ForgotContainer>Forgot Password</ForgotContainer>
        </CustomColumn>
        <CustomColumn mb={2.777}>
          <Text>
            Your username is{" "}
            <span style={{ color: "#47CCD6" }}>{location.state?.username}</span>
            . However, we have sent you the username to{" "}
            <span style={{ color: "#47CCD6" }}>{location.state?.emailId}</span>.
            Please check your inbox.
          </Text>
        </CustomColumn>
        <CustomColumn mb={2.638}>
          <StyledButtonPrimary
            style={{ width: "100%", height: "3.888rem" }}
            onClick={() => history.push("/signIn")}
          >
            Back to Login
          </StyledButtonPrimary>
        </CustomColumn>
      </CustomRow>
    </CommonFirstPage>
  );
};
