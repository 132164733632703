import React, { useMemo } from "react";
import Select from "../../../../components/common/_select";
import PropTypes from "prop-types";

const WorkspaceMemberPermissionCell = ({ row, setWorkspaceMembersData }) => {
  const options = useMemo(
    () => [
      { label: "Maintainer", value: "MAIN" },
      { label: "Developer", value: "DEV" },
      { label: "Viewer", value: "VIEW" },
    ],
    [],
  );

  const permission = options.find(
    (option) => option.value === row.userPermission,
  );
  const setPermission = (newPermission) => {
    setWorkspaceMembersData((oldWorkspaceMembersData) => {
      const newWorkspaceMembersData = JSON.parse(
        JSON.stringify(oldWorkspaceMembersData),
      );
      newWorkspaceMembersData.membersList.forEach((workspaceMember) => {
        if (workspaceMember.userId === row.userId)
          workspaceMember.userPermission = newPermission.value;
      });
      return newWorkspaceMembersData;
    });
  };

  return (
    permission && (
      <Select
        value={permission}
        setValue={setPermission}
        options={options}
        renderValue={(option) => option.label}
        width="100%"
        alwaysShowBorder
        border="0.104rem solid #4B4B4B"
      />
    )
  );
};

WorkspaceMemberPermissionCell.propTypes = {
  row: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    userPermission: PropTypes.string.isRequired,
  }).isRequired,
  setWorkspaceMembersData: PropTypes.func.isRequired,
};

export default WorkspaceMemberPermissionCell;
