import React, { useContext, useState } from "react";
import GenericModal from "../../../../components/common/_genericModal";
import { SnackbarContext } from "../../../../layouts/Context/snackBarContext";
import CustomRow from "../../../../components/common/_customRow";
import styled from "styled-components";
import Column from "../../../../components/common/_customColumn";
import {
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../../../components/common/_buttonNewOne";
import Text from "../../../../components/common/_text";
import axiosAdapter from "../../../../utils";
import { env } from "../../../../env";
import PropTypes from "prop-types";
const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const RemoveUserModal = ({
  isOpen,
  onClose,
  dataRow,
  refetchUsersAndRoles,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const [isApiLoading, setApiLoading] = useState(false);

  const handleRemoveClick = async () => {
    setApiLoading(true);
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/deleteUser",
        {
          emailId: dataRow.emailId,
        },
      );
      setApiLoading(false);
      refetchUsersAndRoles();
      showMessage("User removed successfully", "success");
      onClose();
    } catch (err) {
      console.log("Error: deleteUser", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Failed to remove the user, please try again later");
      }
      setApiLoading(false);
    }
  };

  return (
    <GenericModal
      show={isOpen}
      onClose={onClose}
      disableCloseOnOutsideClick={isApiLoading}
      title={"Remove Member?"}
    >
      <CustomRow>
        <CustomColumn mb={5.555} style={{ opacity: "0.7" }}>
          <Text
            $fontSize={"1.25rem"}
            $color={"#ECECEC"}
          >{`Are you sure you want to delete ${dataRow.fullName}? This member will lose access to all the data and workspaces they are assigned to. This cannot be undone.`}</Text>
        </CustomColumn>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <StyledButtonsecondary
            width="13.888rem"
            style={{ marginRight: "1.111rem" }}
            onClick={onClose}
            disabled={isApiLoading}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledPrimaryButton2
            width="13.888rem"
            onClick={() => handleRemoveClick()}
            disabled={isApiLoading}
          >
            Remove User
          </StyledPrimaryButton2>
        </CustomColumn>
      </CustomRow>
    </GenericModal>
  );
};

RemoveUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dataRow: PropTypes.shape({
    emailId: PropTypes.string,
    fullName: PropTypes.string,
  }).isRequired,
  refetchUsersAndRoles: PropTypes.func.isRequired,
};

export default RemoveUserModal;
