const markSearchText = (text, escapedText) => {
  // Escape special regex characters in the search text to prevent regex injection
  const safeEscapedText = escapedText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  // Split the string into text and tags
  const parts = text.split(/(<[^>]*>)/);

  // Process each part
  const processed = parts.map((part) => {
    if (part.startsWith("<")) {
      // This is a tag, don't modify it
      return part;
    } else {
      // Use a more efficient regex matching approach
      return part
        .split(new RegExp(`(${safeEscapedText})`, "gi"))
        .map((segment) =>
          segment.toLowerCase() === safeEscapedText.toLowerCase()
            ? `<mark style="background-color: #47CCD6; padding: 0rem;">${segment}</mark>`
            : segment,
        )
        .join("");
    }
  });
  // Join the parts back together
  return processed.join("");
};

export default markSearchText;
