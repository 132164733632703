import styled from "styled-components";
import CustomRow from "../../components/common/_customRow";
import { CommonFirstPage } from "../LandingPages/CommonFirstPage";
import Column from "../../components/common/_customColumn";
import { useState, useContext, useEffect } from "react";
import { CountryCodeSelector } from "../../components/common/_countryCodeSelector";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";

const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const ORG_NAME_REGEX = /^[a-zA-Z0-9.,&-]+(?: [a-zA-Z0-9.,&-]+)*$/;

const FULL_NAME_REGEX =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńñòóôöõøśšùúûüųūýÿżźžÀÁÂÄÃÅĄČĆĘÈÉÊËĖĮÌÍÎÏŁŃÑÒÓÔÖÕØŚŠÙÚÛÜŲŪÝŸŻŹŽ ]+$/;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  color: #f6f6f6;
  font-size: 1.527rem;
  line-height: 2.166rem;
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #2f2f2f;
`;

export const SignUp = () => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const [disableFullName, setDisableFullName] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [disableEmailAddress, setDisableEmailAddress] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgNameError, setOrgNameError] = useState("");
  const [disableOrgName, setDisableOrgName] = useState(false);
  const [disableAllButtons, setDisableAllButtons] = useState(false);
  const [inviteHash, setInviteHash] = useState(null);

  // If there is an invite code in the URL, prefill the invitation details
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const inviteCode = urlParams.get("invitationCode");
    if (inviteCode) {
      setInviteHash(inviteCode);
      (async () => {
        try {
          const response = await axiosAdapter(
            "POST",
            env.REACT_APP_URL + "user-management/getInvitationDetails",
            {
              inviteCode,
            },
          );
          showMessage(response.data.remarks, "success");
          setEmailAddress(response.data.data.email);
          setDisableEmailAddress(true);
          setFullName(response.data.data.fullName);
          setDisableFullName(true);
          setOrgName(response.data.data.orgName);
          setDisableOrgName(true);
        } catch (err) {
          console.log("Error: getInvitationDetails", err);
          showMessage(
            err?.response?.data?.remarks ||
              "Unable to obtain the invitation details. Please try again later",
            "error",
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle create account click function
  const validateFullName = (name) => {
    const tempName = name.trim();
    if (!tempName) {
      return {
        isValid: false,
        error: "Please enter a value for the full name",
      };
    }
    if (!(tempName.length >= 3 && tempName.length <= 250)) {
      return {
        isValid: false,
        error: "Full name length should be between 3 and 250 characters",
      };
    }
    if (!FULL_NAME_REGEX.test(tempName)) {
      return {
        isValid: false,
        error:
          "Full name can only include alphabets,spaces and accented alphabets",
      };
    }
    return { isValid: true, value: tempName };
  };

  const validateOrgName = (name) => {
    const tempName = name.trim();
    if (!tempName) {
      return {
        isValid: false,
        error: "Please enter a value for the Organization name",
      };
    }
    if (!(tempName.length >= 3 && tempName.length <= 250)) {
      return {
        isValid: false,
        error:
          "Organization name length should be between 3 and 250 characters",
      };
    }
    if (!ORG_NAME_REGEX.test(tempName)) {
      return {
        isValid: false,
        error: "Organization name can only include a-z, A-Z, 0-9 and .,&-",
      };
    }
    return { isValid: true, value: tempName };
  };

  const validateEmail = (email) => {
    if (!email) {
      return {
        isValid: false,
        error: "Please enter a value for the email address",
      };
    }
    if (email.length > 320) {
      return {
        isValid: false,
        error: "Email length should be less than 320 characters",
      };
    }
    if (!EMAIL_REGEX.test(email)) {
      return { isValid: false, error: "Please enter a valid email address" };
    }
    return { isValid: true, value: email };
  };

  const validatePhoneNumber = (phone) => {
    if (!phone) {
      return {
        isValid: false,
        error: "Please enter a value for the phone number",
      };
    }
    return { isValid: true, value: phone };
  };

  const checkPhoneNumberAvailability = async (phoneNumber, countryCode) => {
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/checkPhoneNumberAvailibility",
        { phoneNumber, countryCode },
      );
      return { isValid: true };
    } catch (err) {
      const error =
        err.response?.data?.remarks ||
        "Unable to check the phone number availability, please try again later";
      return { isValid: false, error };
    }
  };

  const checkOrgNameAvailability = async (orgName) => {
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/checkOrganizationNameAvailibility",
        { organizationName: orgName },
      );
      return { isValid: true };
    } catch (err) {
      const error =
        err.response?.data?.remarks ||
        "Unable to check the organization name availability, please try again later";
      return { isValid: false, error };
    }
  };

  const generateOtpAndRedirect = async (emailAddress, userData) => {
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/generateOtp",
        { emailId: emailAddress, isNewAccount: true },
      );

      history.push("/otpVerification", {
        isRedirected: true,
        isSignUp: true,
        ...userData,
      });
      return { isValid: true };
    } catch (err) {
      const error =
        err.response?.data?.remarks ||
        "Unable to generate OTP, please try again later";
      return { isValid: false, error };
    }
  };

  const handleCreateAccountClick = async () => {
    setDisableAllButtons(true);
    clearErrors();

    // Validate all fields
    const fullNameResult = validateFullName(fullName);
    const orgNameResult = validateOrgName(orgName);
    const emailResult = validateEmail(emailAddress);
    const phoneResult = validatePhoneNumber(phoneNumber);

    // Update error states
    if (!fullNameResult.isValid) setFullNameError(fullNameResult.error);
    if (!orgNameResult.isValid) setOrgNameError(orgNameResult.error);
    if (!emailResult.isValid) setEmailAddressError(emailResult.error);
    if (!phoneResult.isValid) setPhoneNumberError(phoneResult.error);

    // If any validation failed, return early
    if (
      !fullNameResult.isValid ||
      !orgNameResult.isValid ||
      !emailResult.isValid ||
      !phoneResult.isValid
    ) {
      setDisableAllButtons(false);
      return;
    }

    // Check phone number availability
    const phoneAvailability = await checkPhoneNumberAvailability(
      phoneNumber,
      countryCode,
    );
    if (!phoneAvailability.isValid) {
      setPhoneNumberError(phoneAvailability.error);
      setDisableAllButtons(false);
      return;
    }

    // Check organization name availability if needed
    if (!disableOrgName) {
      const orgAvailability = await checkOrgNameAvailability(
        orgNameResult.value,
      );
      if (!orgAvailability.isValid) {
        setOrgNameError(orgAvailability.error);
        setDisableAllButtons(false);
        return;
      }
    }

    // Generate OTP and redirect
    const otpResult = await generateOtpAndRedirect(emailResult.value, {
      emailId: emailResult.value,
      countryCode,
      phoneNumber: phoneResult.value,
      fullName: fullNameResult.value,
      organizationName: orgNameResult.value,
      inviteCode: inviteHash,
    });

    if (!otpResult.isValid) {
      showMessage(otpResult.error, "error");
      setDisableAllButtons(false);
    }
  };

  const clearErrors = () => {
    setFullNameError("");
    setEmailAddressError("");
    setOrgNameError("");
    setPhoneNumberError("");
  };

  return (
    <CommonFirstPage>
      <CustomRow>
        <CustomColumn mb={2.222}>
          <Text>Welcome to Condense! Create an account to continue</Text>
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <InputFieldContainer
            style={
              fullNameError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Full Name"
              name="fullName"
              onChange={(e) => setFullName(e.target.value)}
              value={fullName}
              readOnly={disableFullName}
            />
          </InputFieldContainer>
          {fullNameError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {fullNameError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              columnGap: "1.111rem",
              ...(phoneNumberError && { marginBottom: "0.555rem" }),
            }}
          >
            <CountryCodeSelector
              selectedContryCode={countryCode}
              onContryCodeSelect={(item) => setCountryCode(item.code)}
            />
            <InputFieldContainer
              style={phoneNumberError ? { borderColor: "#EA3A3A" } : {}}
            >
              <InputField
                placeholder="Phone Number"
                name="phoneNumber"
                onChange={(e) =>
                  setPhoneNumber(e.target.value.trim().replace(/\D/g, ""))
                }
                value={phoneNumber}
              />
            </InputFieldContainer>
          </div>
          {phoneNumberError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
                marginTop: "0.555rem",
              }}
            >
              {phoneNumberError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <InputFieldContainer
            style={
              emailAddressError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Work Email Address"
              name="emailAddress"
              onChange={(e) =>
                setEmailAddress(e.target.value.toLowerCase().trim())
              }
              value={emailAddress}
              readOnly={disableEmailAddress}
            />
          </InputFieldContainer>
          {emailAddressError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {emailAddressError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={2.222}>
          <InputFieldContainer
            style={
              orgNameError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Organization Name"
              name="organizationName"
              onChange={(e) => setOrgName(e.target.value)}
              value={orgName}
              readOnly={disableOrgName}
            />
          </InputFieldContainer>
          {orgNameError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {orgNameError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <StyledButtonPrimary
            style={{ width: "100%", height: "3.888rem" }}
            onClick={handleCreateAccountClick}
            disabled={
              disableAllButtons ||
              !fullName ||
              !phoneNumber ||
              !emailAddress ||
              !orgName ||
              !countryCode
            }
          >
            Create Account
          </StyledButtonPrimary>
        </CustomColumn>
        <CustomColumn>
          <Text
            style={{
              color: "#777777",
              fontSize: "1.111rem",
              lineHeight: "1.388rem",
            }}
          >
            Already have an account?
            <button
              type="button"
              aria-label="Login Now"
              style={{
                background: "none",
                border: "none",
                padding: 0,
                color: "#CCCCCC",
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: "1.111rem",
                marginLeft: "0.277rem",
              }}
              onClick={() => !disableAllButtons && history.push("/signIn")}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  !disableAllButtons && history.push("/signIn");
                }
              }}
            >
              Login Now
            </button>
          </Text>
        </CustomColumn>
      </CustomRow>
    </CommonFirstPage>
  );
};
