import React, { useCallback, useEffect, useState, useContext } from "react";
import styled from "styled-components";

import LandingApplicationPage from "./LandingApplicationPage";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import ApplicationList from "./ApplicationList";
import loadingAnimation from "../../assets/images/Loading Animation.gif";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 3.95rem);
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 240px;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #2f2f2f;
  border-radius: 2px;
  box-shadow: -8px 4px 9px 0px #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 4px;
`;

const fetchAllApplications = async (workspaceId) => {
  const result = await axiosAdapter(
    "POST",
    env.REACT_APP_URL + "pipeline/getAllApplications",
    JSON.stringify({ workspaceId: workspaceId }),
  );
  return result;
};

function ApplicationPage() {
  const { showMessage } = useContext(SnackbarContext);
  const history = useHistory();

  const [applicationData, setApplicationData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let workspaceId = localStorage.getItem("selectedWorkspaceId");
    if (!workspaceId) {
      showMessage("Please select a workspace");
      history.push("/");
    }
    workspaceId = parseInt(workspaceId);
    (async () => {
      setLoading(true);
      if (!workspaceId) {
        return;
      }
      try {
        const applicationList = await fetchAllApplications(workspaceId);
        setApplicationData(applicationList.data.data);
        setLoading(false);
      } catch (err) {
        console.log("Error: getAllApplications", err);
        setLoading(false);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Failed to get applications, please try again later !!!");
        }
      }
    })();
  }, [history, showMessage]);

  const handleDelete = useCallback((deletedId) => {
    setApplicationData((prevData) =>
      prevData.filter((app) => app.id !== deletedId),
    );
  }, []);

  const refetchApplications = useCallback(async () => {
    try {
      let workspaceId = localStorage.getItem("selectedWorkspaceId");
      if (!workspaceId) {
        showMessage("Please select a workspace");
      }
      workspaceId = parseInt(workspaceId);
      setLoading(true);
      const applicationList = await fetchAllApplications(workspaceId);
      setApplicationData(applicationList.data.data);
      setLoading(false);
    } catch (err) {
      console.log("Error: getAllApplications", err);
      setLoading(false);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Failed to get applications, please try again later !!!");
      }
    }
  }, [showMessage]);

  const renderContent = () => {
    if (loading) {
      return (
        <ParentContainer>
          <AnimationContainer>
            <ScrollAnimationBar />
            <LoadingAnimation src={loadingAnimation} alt="Loading Animation" />
          </AnimationContainer>
        </ParentContainer>
      );
    }

    if (applicationData.length > 0) {
      return (
        <ApplicationList
          data={applicationData}
          onDelete={handleDelete}
          refetchApplications={refetchApplications}
        />
      );
    }

    return <LandingApplicationPage />;
  };

  return <>{renderContent()}</>;
}

export default ApplicationPage;
