import GeoFenceLeaflet from "./geoFenceLeaflet";
import PropTypes from "prop-types";
const GeoFenceMain = ({ configIdForGeoFence }) => {
  return (
    <div
      style={{
        height: "calc(100vh - 200px)",
        backgroundColor: "#171717",
        overflow: "auto",
      }}
    >
      <GeoFenceLeaflet configIdForGeoFence={configIdForGeoFence} />
    </div>
  );
};

GeoFenceMain.propTypes = {
  configIdForGeoFence: PropTypes.number,
};
export default GeoFenceMain;
