import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const OtpContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OtpInput = styled.input`
  width: 3.333rem;
  height: 3.333rem;
  text-align: center;
  font-size: 1.388rem;
  background-color: #2f2f2f;
  border-radius: 0.555rem;
  outline: none;
  border: 0.0694rem solid #2f2f2f;
  color: #f6f6f6;
  &:focus {
    outline: none;
    border: 0.0694rem solid #2f2f2f;
  }
`;

function OtpInputField({ onChange, isError }) {
  const numberOfDigits = 6;
  const otpBoxReference = useRef([]);
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));

  useEffect(() => {
    otpBoxReference.current = otpBoxReference.current.slice(0, numberOfDigits);
  }, []);

  const focusNextInput = (index) => {
    const nextIndex = index + 1;
    if (nextIndex < numberOfDigits) {
      otpBoxReference.current[nextIndex].focus();
    }
  };

  const focusPrevInput = (index) => {
    const prevIndex = index - 1;
    if (prevIndex >= 0) {
      otpBoxReference.current[prevIndex].focus();
    }
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const parsedValue = parseInt(value, 10);
    let newArr = [...otp];
    newArr[index] = value;
    if (!isNaN(parsedValue) && value.length === 1) {
      setOtp(newArr);
      focusNextInput(index);
    }
  };

  const handleBackspaceAndEnter = (e, index) => {
    if (e.key === "Backspace" && index >= 0) {
      let newArr = [...otp];
      newArr[index] = "";
      setOtp(newArr);
      focusPrevInput(index);
    }
    if (e.key === "tab" && e.target.value && index < numberOfDigits - 1) {
      focusNextInput(index);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, numberOfDigits);

    // Check if all characters in the pasted data are digits
    const allDigits = pastedData
      .split("")
      .every((char) => !isNaN(char) && char !== " ");

    if (allDigits) {
      const newOtp = [...otp];

      pastedData.split("").forEach((char, index) => {
        if (index < numberOfDigits) {
          newOtp[index] = char;
        }
      });
      setOtp(newOtp);

      const firstEmptyIndex = newOtp.findIndex((val) => val === "");
      if (firstEmptyIndex !== -1) {
        otpBoxReference.current[firstEmptyIndex].focus();
      }
    }
  };

  useEffect(() => {
    onChange(otp.join(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  return (
    <OtpContainer>
      {Array(numberOfDigits)
        .fill(0)
        .map((_, index) => (
          <OtpInput
            key={_}
            type="text"
            maxLength="1"
            placeholder="0"
            id={`otp-input-${index}`}
            value={otp[index]}
            onChange={(e) => handleInputChange(e, index)}
            onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
            onPaste={(e) => handlePaste(e)}
            ref={(ref) => (otpBoxReference.current[index] = ref)}
            style={{ ...(isError && { borderColor: "#EA3A3A" }) }}
          />
        ))}
    </OtpContainer>
  );
}

OtpInputField.propTypes = {
  onChange: PropTypes.func.isRequired,
  isError: PropTypes.bool,
};

OtpInputField.defaultProps = {
  isError: false,
};

export default OtpInputField;
