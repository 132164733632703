import React, { useState } from "react";
import { ReactComponent as SelectOpenIcon } from "../../assets/images/select_open_icon.svg";
import { ReactComponent as SelectCloseIcon } from "../../assets/images/select_close_icon.svg";
import PropTypes from "prop-types";
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import styled from "styled-components";
import { remToPx } from "../../utils";

const StyledSelectOpenIcon = styled(SelectOpenIcon)`
  height: 1.111rem;
  width: 1.111rem;
`;

const StyledSelectCloseIcon = styled(SelectCloseIcon)`
  height: 1.111rem;
  width: 1.111rem;
`;

const SelectInputContainer = styled.div`
  padding: ${({ $containerPadding }) => $containerPadding};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.555rem;
  width: ${({ $dropdownWidth }) => $dropdownWidth};
  border-radius: 0.555rem;
  border: ${({ $border }) => $border};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  cursor: ${({ $isDisabled }) => ($isDisabled ? "not-allowed" : "pointer")};
  color: ${({ $selectionFontColor }) => $selectionFontColor};
  font-size: ${({ $selectionFontSize }) => $selectionFontSize};
  font-weight: ${({ $selectionFontWeight }) => $selectionFontWeight};
  line-height: ${({ $selectionLineHeight }) => $selectionLineHeight};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.isLabelPresent {
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
    border-left-color: ${({ $labelSeperatorColor }) => $labelSeperatorColor};
  }
`;

const SelectLabelContainer = styled.div`
  padding: ${({ $containerPadding }) => $containerPadding};
  max-width: ${({ $labelMaxWidth }) => $labelMaxWidth};
  border: ${({ $border }) => $border};
  border-radius: 0.555rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  font-size: ${({ $labelFontSize }) => $labelFontSize};
  font-weight: ${({ $labelFontWeight }) => $labelFontWeight};
  color: ${({ $labelColor }) => $labelColor};
  line-height: ${({ $selectionLineHeight }) => $selectionLineHeight};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SelectionOptionsContainer = styled.div`
  position: relative;
  z-index: 1;
  padding: 0.694rem;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: flex-start;
  gap: 0.555rem;
  border: ${({ $border }) => $border};
  border-radius: 0.833rem;
  max-height: ${({ $maxHeight }) => $maxHeight};
`;

const SelectionOptionItem = styled.div`
  cursor: pointer;
  font-size: ${({ $selectionFontSize }) => $selectionFontSize};
  font-weight: ${({ $selectionFontWeight }) => $selectionFontWeight};
  color: ${({ $selectionFontColor }) => $selectionFontColor};
  line-height: 1;
  padding: 0.694rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  border-radius: 0.555rem;
  &:hover {
    background-color: #4b4b4b;
  }
`;

/**
 * A generic selection component with a label. If no label is provided, label container will not be rendered.
 *
 * @param {string} label Label to show before the selection input.
 * @param {string} [labelFontSize="0.972rem"] Font size of the label.
 * @param {string} [labelFontColor="#B0B0B0"] Font color of the label.
 * @param {string} [labelFontWeight="bold"] Font weight of the label.
 * @param {string} [labelMaxWidth="auto"] Max width of the label.
 * @param {string} [containerPadding="0.763rem"] Padding of the container.
 * @param {string|number} [selectedOption] Currently selected option.
 * @param {Array<number|string|object>} [selectionOptions=[]] Array of options to select from.
 * @param {function} [onSelectionChange] Called when the selection changes.
 * @param {function} [selectionNameFunction] Called to get the name to display for each option.
 * @param {string} [selectionFontSize="0.972rem"] Font size of the selection.
 * @param {string} [selectionFontColor="#F6F6F6"] Font color of the selection.
 * @param {string} [selectionFontWeight="bold"] Font weight of the selection.
 * @param {string} [selectionLineHeight="1.111rem"] Line height of the selection.
 * @param {string} [dropdownWidth="auto"] Width of the dropdown.
 * @param {string} [inputContainerBorder="0.0694rem solid #F6F6F6"] Border of the input container.
 * @param {string} [selectionInputBackgroundColor="inherit"] Background color of the selection input.
 * @param {string} [optionsContainerBackgroundColor="#2F2F2F"] Background color of the options container.
 * @param {string} [optionsContainerBorder="0.0694rem solid #ECECEC"] Border of the options container.
 * @param {string} [optionsContainerMaxHeight="16rem"] Max height of the options container.
 * @param {boolean} [isDisabled=false] Whether the component is disabled.
 */
const GenericSelectionWithLabel = ({
  label,
  labelFontSize = "0.972rem",
  labelFontColor = "#B0B0B0",
  labelFontWeight = "bold",
  labelMaxWidth = "auto",
  containerPadding = "0.763rem",
  selectedOption,
  selectionOptions = [],
  onSelectionChange,
  selectionNameFunction,
  selectionFontSize = "0.972rem",
  selectionFontColor = "#F6F6F6",
  selectionFontWeight = "bold",
  selectionLineHeight = "1.111rem",
  dropdownWidth = "auto",
  inputContainerBorder = "0.0694rem solid #F6F6F6",
  selectionInputBackgroundColor = "inherit",
  optionsContainerBackgroundColor = "#2F2F2F",
  optionsContainerBorder = "0.0694rem solid #ECECEC",
  optionsContainerMaxHeight = "16rem",
  isDisabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(remToPx(0.277)),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
    placement: "bottom-end",
  });
  const click = useClick(context, {
    enabled: !isDisabled,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        {label && (
          <SelectLabelContainer
            $containerPadding={containerPadding}
            $labelMaxWidth={labelMaxWidth}
            $backgroundColor={selectionInputBackgroundColor}
            $labelFontSize={labelFontSize}
            $labelColor={labelFontColor}
            $labelFontWeight={labelFontWeight}
            $border={inputContainerBorder}
            $selectionLineHeight={selectionLineHeight}
            {...getReferenceProps({
              ref: refs.setReference,
            })}
          >
            {label}
          </SelectLabelContainer>
        )}
        <SelectInputContainer
          $containerPadding={containerPadding}
          $dropdownWidth={dropdownWidth}
          $border={inputContainerBorder}
          $backgroundColor={selectionInputBackgroundColor}
          $isDisabled={isDisabled}
          $selectionFontColor={selectionFontColor}
          $selectionFontSize={selectionFontSize}
          $selectionFontWeight={selectionFontWeight}
          $selectionLineHeight={selectionLineHeight}
          $labelSeperatorColor={labelFontColor}
          {...getReferenceProps({
            ref: refs.setReference,
          })}
          className={label ? "isLabelPresent" : ""}
        >
          {selectionNameFunction
            ? selectionNameFunction(selectedOption)
            : selectedOption}
          {open ? <StyledSelectOpenIcon /> : <StyledSelectCloseIcon />}
        </SelectInputContainer>
      </div>
      {open && !isDisabled && (
        <FloatingPortal>
          <SelectionOptionsContainer
            $border={optionsContainerBorder}
            $backgroundColor={optionsContainerBackgroundColor}
            $maxHeight={optionsContainerMaxHeight}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {selectionOptions.map((option, index) => (
              <SelectionOptionItem
                key={option}
                onClick={() => {
                  onSelectionChange(option);
                  setOpen(false);
                }}
                $selectionFontColor={selectionFontColor}
                $selectionFontSize={selectionFontSize}
                $selectionFontWeight={selectionFontWeight}
                $selectionLineHeight={selectionLineHeight}
              >
                {selectionNameFunction ? selectionNameFunction(option) : option}
              </SelectionOptionItem>
            ))}
          </SelectionOptionsContainer>
        </FloatingPortal>
      )}
    </>
  );
};

GenericSelectionWithLabel.propTypes = {
  label: PropTypes.string,
  labelFontSize: PropTypes.string,
  labelFontColor: PropTypes.string,
  labelFontWeight: PropTypes.string,
  labelMaxWidth: PropTypes.string,
  containerPadding: PropTypes.string,
  selectedOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  selectionOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  ),
  onSelectionChange: PropTypes.func.isRequired,
  selectionNameFunction: PropTypes.func,
  selectionFontSize: PropTypes.string,
  selectionFontColor: PropTypes.string,
  selectionFontWeight: PropTypes.string,
  selectionLineHeight: PropTypes.string,
  dropdownWidth: PropTypes.string,
  inputContainerBorder: PropTypes.string,
  selectionInputBackgroundColor: PropTypes.string,
  optionsContainerBackgroundColor: PropTypes.string,
  optionsContainerBorder: PropTypes.string,
  optionsContainerMaxHeight: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default GenericSelectionWithLabel;
