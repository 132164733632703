import { useState, useContext } from "react";
import styled from "styled-components";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import SettingsIcon from "../../assets/images/card_setting_icon.svg";
import GoBackIcon from "../../assets/images/card_back_icon.svg";
import EditIcon from "../../assets/images/card_edit_icon.svg";
import DeleteIcon from "../../assets/images/card_delete_icon.svg";
import CustomConnectorDeletionModal from "./CustomConnectorDeletionModal";
import CustomConnectorEditModal from "./CustomConnectorEditModal";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { applicationIconMapper } from "../../components/common/_applicationIconMapper";
import PropTypes from "prop-types";

const CardContainer = styled.div`
  border-radius: 0.84rem;
  background-color: #2f2f2f;
  display: flex;
  justify-content: space-between;
  padding: 0.84rem;
  height: 11.11rem;
`;

const LeftLine = styled.div`
  width: 0.277rem;
  background-color: #eb6f95;
  border-radius: 0.3rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
`;

const Text = styled.p`
  font-family: Articulat CF Medium;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;

  &.multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-top: 1.39rem;
`;

const CustomConnectorCard = ({
  connectorId,
  connectorName,
  connectorVersion,
  publisher,
  description,
  iconFile,
  isClickable,
  handleCustomConnectorCardClick,
}) => {
  const { showMessage } = useContext(SnackbarContext);

  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);

  return (
    <>
      <CardContainer
        style={
          isSettingsOpen
            ? { paddingBottom: "0px", paddingLeft: "0px", paddingRight: "0px" }
            : {}
        }
        onClick={() => {
          if (isClickable) {
            handleCustomConnectorCardClick(
              connectorId,
              connectorName,
              description,
              connectorVersion,
            );
          } else {
            showMessage("You don't have permission to perform this operation");
          }
        }}
      >
        {!isSettingsOpen && <LeftLine />}
        <ContentContainer
          style={!isSettingsOpen ? { paddingLeft: "0.84rem" } : {}}
        >
          <CustomRow
            style={{
              flexWrap: "nowrap",
              columnGap: "0.84rem",
              ...(isSettingsOpen
                ? {
                    paddingLeft: "0.84rem",
                    paddingRight: "0.84rem",
                  }
                : {
                    marginTop: "0.277rem",
                  }),
            }}
          >
            <Column xs={isSettingsOpen ? 2 : 1.4}>
              <img
                src={applicationIconMapper[iconFile || "ConversionPath.svg"]}
                alt="Connector Icon"
                style={
                  !isSettingsOpen
                    ? {
                        width: "2.225rem",
                        height: "2.225rem",
                        maxWidth: "2.225rem",
                        maxHeight: "2.225rem",
                        background: "#4B4B4B",
                        borderRadius: "0.277rem",
                      }
                    : {
                        width: "3.33rem",
                        height: "3.33rem",
                        maxWidth: "3.33rem",
                        maxHeight: "3.33rem",
                        background: "#4B4B4B",
                        borderRadius: "0.277rem",
                      }
                }
              />
            </Column>
            <Column xs={isSettingsOpen ? 8 : 8.5}>
              <CustomRow>
                <Column>
                  <Text
                    style={{
                      color: "#F6F6F6",
                      fontSize: "1.112rem",
                      fontFamily: "Articulat CF Medium",
                      fontWeight: "bold",
                    }}
                  >
                    {connectorName}
                  </Text>
                </Column>
                {isSettingsOpen && (
                  <Column>
                    <Text
                      style={{
                        color: "#777777",
                        fontSize: "0.84rem",
                        lineHeight: "0.972rem",
                        letterSpacing: "0px",
                      }}
                    >
                      Published by {publisher}
                    </Text>
                  </Column>
                )}
                {isSettingsOpen && (
                  <Column>
                    <Text
                      style={{
                        color: "#777777",
                        fontSize: "0.84rem",
                        lineHeight: "0.972rem",
                      }}
                    >
                      {connectorVersion}
                    </Text>
                  </Column>
                )}
              </CustomRow>
            </Column>
            <Column
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (isClickable) {
                  setSettingsOpen(!isSettingsOpen);
                } else {
                  showMessage(
                    "You don't have permission to perform this operation",
                  );
                }
              }}
            >
              <img
                src={isSettingsOpen ? GoBackIcon : SettingsIcon}
                alt={isSettingsOpen ? "Go Back Icon" : "Settings Icon"}
                style={{ height: "1.39rem", width: "1.39rem" }}
              />
            </Column>
          </CustomRow>
          {!isSettingsOpen && (
            <CustomRow>
              <Column xs={7}>
                <TitleAndSubtitleContainer>
                  <Text
                    style={{
                      color: "#777777",
                      fontSize: "0.7rem",
                      lineHeight: "1.041rem",
                      fontWeight: "bold",
                      fontFamily: "Articulat CF Medium",
                    }}
                  >
                    Published By
                  </Text>
                  <Text
                    style={{
                      color: "#eb6f95",
                      fontSize: "0.84rem",
                      lineHeight: "0.972rem",
                      letterSpacing: "0",
                    }}
                  >
                    {publisher}
                  </Text>
                </TitleAndSubtitleContainer>
              </Column>
              <Column xs={5}>
                <TitleAndSubtitleContainer>
                  <Text
                    style={{
                      color: "#777777",
                      fontSize: "0.7rem",
                      lineHeight: "1.041rem",
                      fontFamily: "Articulat CF Medium",
                      fontWeight: "bold",
                      letterSpacing: "0px",
                    }}
                  >
                    Version
                  </Text>
                  <Text
                    style={{
                      color: "#eb6f95",
                      fontSize: "0.84rem",
                      lineHeight: "0.972rem",
                      letterSpacing: "0",
                    }}
                  >
                    {connectorVersion}
                  </Text>
                </TitleAndSubtitleContainer>
              </Column>
            </CustomRow>
          )}
          <CustomRow
            style={
              isSettingsOpen
                ? {
                    paddingLeft: "0.84rem",
                    paddingRight: "0.84rem",
                  }
                : {
                    marginBottom: "0.277rem",
                  }
            }
          >
            <Column>
              <Text
                className="multiline-ellipsis"
                style={{
                  color: "#F6F6F6",
                  fontSize: "0.84rem",
                  lineHeight: "0.972rem",
                  marginTop: "1.39rem",
                  height: "2.225rem",
                }}
              >
                {description}
              </Text>
            </Column>
          </CustomRow>
          {isSettingsOpen && (
            <CustomRow
              style={{
                padding: "0.69rem 0.84rem",
                backgroundColor: "#EE6F4766",
                borderRadius: "0px 0 0.84rem 0.84rem",
                display: "flex",
                alignItems: "center",
              }}
              onClick={(e) => {
                e.stopPropagation();
                return null;
              }}
            >
              <Column xs={4.7}>
                <Text
                  style={{
                    textDecoration: "underLine",
                    color: "#F6F6F6",
                    fontSize: "0.84rem",
                    lineHeight: "0.84rem",
                    cursor: "not-allowed",
                  }}
                >
                  Version History
                </Text>
              </Column>
              <Column
                xs={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <button
                  type="button"
                  aria-label="Edit"
                  style={{
                    background: "none",
                    border: "none",
                    padding: 0,
                    cursor: "pointer",
                    height: "1.112rem",
                    width: "1.112rem",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isClickable) {
                      setEditOpen(true);
                    } else {
                      showMessage(
                        "You don't have permission to perform this operation",
                      );
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      e.stopPropagation();
                      if (isClickable) {
                        setEditOpen(true);
                      } else {
                        showMessage(
                          "You don't have permission to perform this operation",
                        );
                      }
                    }
                  }}
                >
                  <img
                    src={EditIcon}
                    alt="Edit Icon"
                    style={{ width: "100%", height: "100%" }}
                  />
                </button>

                <button
                  type="button"
                  aria-label="Delete"
                  style={{
                    background: "none",
                    border: "none",
                    padding: 0,
                    cursor: "pointer",
                    height: "1.112rem",
                    width: "1.112rem",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isClickable) {
                      setDeleteOpen(true);
                    } else {
                      showMessage(
                        "You don't have permission to perform this operation",
                      );
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      e.stopPropagation();
                      if (isClickable) {
                        setDeleteOpen(true);
                      } else {
                        showMessage(
                          "You don't have permission to perform this operation",
                        );
                      }
                    }
                  }}
                >
                  <img
                    src={DeleteIcon}
                    alt="Delete Icon"
                    style={{ width: "100%", height: "100%" }}
                  />
                </button>
              </Column>
            </CustomRow>
          )}
        </ContentContainer>
      </CardContainer>
      {isDeleteOpen && (
        <CustomConnectorDeletionModal
          customConnectorId={connectorId}
          customConnectorName={connectorName}
          isOpen={isDeleteOpen}
          onClose={() => setDeleteOpen(false)}
        />
      )}
      {isEditOpen && (
        <CustomConnectorEditModal
          customConnectorId={connectorId}
          customConnectorName={connectorName}
          isOpen={isEditOpen}
          onClose={() => setEditOpen(false)}
          customConnectorDescription={description}
          customConnectorVersion={connectorVersion}
          customConnectorIconFile={iconFile}
        />
      )}
    </>
  );
};

CustomConnectorCard.propTypes = {
  connectorId: PropTypes.string.isRequired,
  connectorName: PropTypes.string.isRequired,
  connectorVersion: PropTypes.string.isRequired,
  publisher: PropTypes.string,
  description: PropTypes.string,
  iconFile: PropTypes.string,
  isClickable: PropTypes.bool,
  handleCustomConnectorCardClick: PropTypes.func,
};

CustomConnectorCard.defaultProps = {
  publisher: "",
  description: "",
  iconFile: "",
  isClickable: false,
  handleCustomConnectorCardClick: () => {},
};

export default CustomConnectorCard;
