import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import PropTypes from "prop-types";
import React, { cloneElement, isValidElement, useState } from "react";
import { remToPx } from "../../utils";

const Dropdown = ({
  children,
  content,
  initialOpen = false,
  isSameWidthAsParent = false,
  offsetValue = remToPx(0.416),
  placement = "bottom",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(offsetValue),
      flip(),
      shift(),
      ...(isSameWidthAsParent
        ? [
            size({
              apply({ rects, elements }) {
                Object.assign(elements.floating.style, {
                  width: `${rects.reference.width}px`,
                });
              },
            }),
          ]
        : []),
    ],
    whileElementsMounted: autoUpdate,
    placement,
  });

  const click = useClick(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);
  const childrenRef = useMergeRefs([refs.setReference, children?.ref]);
  const childrenWithProps = isValidElement(children)
    ? cloneElement(
        children,
        getReferenceProps({
          ref: childrenRef,
          ...children.props,
          "data-state": context.open ? "open" : "closed",
        }),
      )
    : null;
  const contentRef = useMergeRefs([refs.setFloating, content?.ref]);
  const contentWithProps = isValidElement(content)
    ? cloneElement(
        content,
        getFloatingProps({
          ref: contentRef,
          ...content.props,
          style: floatingStyles,
        }),
      )
    : null;

  return (
    <>
      {childrenWithProps}
      {open && <FloatingPortal>{contentWithProps}</FloatingPortal>}
    </>
  );
};

Dropdown.propTypes = {
  children: PropTypes.element,
  content: PropTypes.node.isRequired,
  initialOpen: PropTypes.bool,
  isSameWidthAsParent: PropTypes.bool,
  offsetValue: PropTypes.number,
  placement: PropTypes.oneOf([
    "top",
    "bottom",
    "left",
    "right",
    "top-start",
    "top-end",
    "bottom-start",
    "bottom-end",
    "left-start",
    "left-end",
    "right-start",
    "right-end",
  ]),
  open: PropTypes.bool,
  onOpenChange: PropTypes.func,
};

Dropdown.defaultProps = {
  initialOpen: false,
  isSameWidthAsParent: false,
  open: undefined,
  onOpenChange: undefined,
};

export default Dropdown;
