import React from "react";
import styled from "styled-components";
import ApplicationEmpty from "../../assets/images/Application Empty State Full updated.svg";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";

const Container = styled.div`
  padding: 2.8rem 4.62rem 0px 4.62rem;
  width: 100%;
  height: calc(100vh - 3.95rem);
  background-color: #0d0d0d;
`;

const Title = styled.div`
  font-family: Articulat CF Medium;
  font-size: 3.335rem;
  line-height: 62.4px;
  color: #f6f6f6;
  font-weight: bold;
`;
const Content = styled.div`
  font-family: Articulat CF Medium;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
  color: #cccccc;
  padding-top: 1.3rem;
  letter-spacing: 0px;
`;

function LandingApplicationPage() {
  const history = useHistory();

  const handleClick = () => {
    history.push("/createApplication");
  };

  return (
    <Container>
      <CustomRow>
        <Column xs={9} sm={10} lg={12}>
          <Title>Get Started with Your First Application!</Title>
        </Column>
      </CustomRow>
      <CustomRow>
        <Column xs={9}>
          <Content>
            Looks like you haven’t created any applications yet! Start Creating
            an application to view them here.
          </Content>
        </Column>
      </CustomRow>
      <StyledButtonPrimary
        style={{ width: "21.11rem", marginTop: "2.2rem" }}
        onClick={handleClick}
      >
        Add New Application
      </StyledButtonPrimary>
      <div
        style={{
          paddingTop: "3.3rem",
          width: "100%",
        }}
      >
        <img src={ApplicationEmpty} alt="" width="100%"></img>
      </div>
    </Container>
  );
}

export default LandingApplicationPage;
