import { forwardRef, useContext, useState } from "react";
import styled from "styled-components";
import { GrafanaUserCredentialsContext } from "../../context/GrafanaUserCredentialsContext";
import { AuthContext } from "../../context/AuthContext";
import { env } from "../../env";
import { StyledButtonPrimary } from "../common/_buttonNewOne";
import { ReactComponent as CopyContentIcon } from "../../assets/images/copy_content_icon.svg";
import DontShowPasswordIcon from "../../assets/images/dont_show_password_icon.svg";
import ShowPasswordIcon from "../../assets/images/show_password_icon.svg";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import PropTypes from "prop-types";

const StyledCopyIcon = styled(CopyContentIcon)`
  height: 1.527rem;
  width: 1.666rem;
  cursor: pointer;
`;

const GrafanaCredentialsContainer = styled.div`
  background: #2f2f2f;
  width: 16.666rem;
  border: 0.069rem solid #5e5e5e;
  border-radius: 0.833rem;
  box-shadow: 0.277rem 0.277rem 1.111rem 0.277rem #0000001c;
  z-index: 1;
  width: 16.666rem;
  padding: 1.111rem;
`;

const StyleRow = styled.div`
  display: flex;
  align-items: center;
`;

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 12.7rem;
`;

const Text = styled.div`
  font-family: Articulat CF Medium;
  margin: 0;
  color: #777777;
  font-size: 0.84rem;
`;

const SubText = styled.div`
  font-family: Articulat CF Medium;
  color: #f6f6f6;
  font-size: 1rem;
`;

const GrafanaCredentialsDropdown = forwardRef((props, ref) => {
  const { username, password, isLoading, isError } = useContext(
    GrafanaUserCredentialsContext,
  );
  const { userRole } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const { showMessage } = useContext(SnackbarContext);

  // Functions to copy username and password
  const handleUsernameCopy = () => {
    navigator.clipboard.writeText(username);
    showMessage("Grafana Username copied to clipboard", "success");
  };

  const handlePasswordCopy = () => {
    navigator.clipboard.writeText(password);
    showMessage("Grafana Password copied to clipboard", "success");
  };
  const renderCredentialsContent = () => {
    if (userRole !== "ADMIN") {
      return (
        <StyleRow>
          <Text>This is only available for Admins of the Organization</Text>
        </StyleRow>
      );
    }

    if (isError) {
      return (
        <StyleRow>
          <Text>
            Unable to obtain Grafana Credentials. Please try again later.
          </Text>
        </StyleRow>
      );
    }

    if (isLoading) {
      return (
        <StyleRow>
          <Text>Loading...</Text>
        </StyleRow>
      );
    }

    return (
      <>
        <StyleRow>
          <TitleAndSubtitleContainer>
            <Text>Username</Text>
            <SubText>{username}</SubText>
          </TitleAndSubtitleContainer>
          <StyledCopyIcon onClick={handleUsernameCopy} />
        </StyleRow>
        <StyleRow style={{ paddingTop: "1.115rem" }}>
          <TitleAndSubtitleContainer>
            <Text>
              Password
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                aria-label={showPassword ? "Hide Password" : "Show Password"}
              >
                <img
                  src={showPassword ? ShowPasswordIcon : DontShowPasswordIcon}
                  alt=""
                  style={{
                    height: "1rem",
                    width: "1rem",
                    marginBottom: "0.2rem",
                    marginLeft: "0.5rem",
                  }}
                />
              </button>
            </Text>
            <SubText>
              {showPassword ? password : "*".repeat(password.length)}
            </SubText>
          </TitleAndSubtitleContainer>
          <StyledCopyIcon onClick={handlePasswordCopy} />
        </StyleRow>
        <StyleRow style={{ marginTop: "1.115rem" }}>
          <StyledButtonPrimary
            onClick={() => {
              const baseUrl = env.REACT_APP_URL;
              const grafanaUrl = `${baseUrl}grafana/login`;
              window.open(grafanaUrl, "_blank");

              if (props.$closeDropdown) {
                props.$closeDropdown();
              }
            }}
            style={{ width: "100%" }}
          >
            Open Grafana
          </StyledButtonPrimary>
        </StyleRow>
      </>
    );
  };

  return (
    <GrafanaCredentialsContainer {...props} ref={ref}>
      {renderCredentialsContent()}
    </GrafanaCredentialsContainer>
  );
});

GrafanaCredentialsDropdown.propTypes = {
  closeDropdown: PropTypes.func,
  $closeDropdown: PropTypes.func,
};

export default GrafanaCredentialsDropdown;
