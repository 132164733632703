import styled from "styled-components";
import PropTypes from "prop-types";

const MoreInfoCard = styled.div`
  background-color: #2f2f2f;
  border-radius: 0.84rem;
  height: 61.5%;
  overflow: auto;
  margin-left: 1.112rem;
  padding: 1.67rem;
  font-family: "Articulat CF Medium";
`;

const MoreInfoPart = (props) => {
  const { content } = props;

  return (
    <MoreInfoCard>
      <div
        dangerouslySetInnerHTML={{
          __html:
            content ||
            "<div style='height: 100%; width: 100%; text-align: left; font-size: 1.67rem; font-family: Articulat CF Medium;'>No Config Template</div>",
        }}
        style={{ color: "white" }}
      />
    </MoreInfoCard>
  );
};

MoreInfoPart.propTypes = {
  content: PropTypes.string,
};

export default MoreInfoPart;
