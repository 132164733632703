import { useCallback, useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import MoreInfoPart from "../../components/ConfigForm/moreInfoPart";
import ConfigForm from "../../components/ConfigForm/newConfigForm";
import backIcon from "../../assets/images/left_arrow_icon.svg";
import styled from "styled-components";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import GenericModal from "../../components/common/_genericModal";
import Stepper from "../../pages/WorkSpaceConnectors/Stepper";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
  StyledDeleteButton,
} from "../../components/common/_buttonNewOne";
import GridBackground from "../../assets/images/grid 1 (1).png";
import ReactFlowNodeCard from "./ReactFlowNodeCard";
import Column from "../../components/common/_customColumn";
import CustomRow from "../../components/common/_customRow";

const ConnectorConfigBottomButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.112rem 0px;
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fafafa;
  font-family: "Articulat CF Medium";
  margin-top: 0.277rem;
  margin-bottom: 1.39rem;
`;

const NavbarLeftConnect = styled.div`
  display: flex;
  gap: 1.112rem;
  font-size: 1.67rem;
  font-weight: bold;
`;

const Icon = styled.div`
  height: 2.225rem;
  width: 2.225rem;
  margin-bottom: 5px;
  cursor: pointer;
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
`;

const ModalDescription = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.555rem;
  color: #ececec;
  opacity: 0.7;
  margin-bottom: 2.084rem;
  display: flex;
  letter-spacing: 0px;
`;

const ModalButtonContainer = styled.div`
  margin-top: 2.084rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.39rem;
`;

const PreviewContainer = styled.div`
  background-color: #2f2f2f;
  height: 100%;
  border-radius: 0.84rem;
  background-image: url(${GridBackground});
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-left: 1.112rem;
`;

const PreviewTitle = styled.div`
  font-family: Articulat CF Medium;
  font-size: 1.67rem;
  color: #f6f6f6;
  position: absolute;
  top: 1.112rem;
  left: 1.67rem;
  font-weight: bold;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  color: #f6f6f6;
`;

const Label = styled.div`
  color: #f6f6f6;
  font-family: "Articulat CF Medium";
  font-size: 0.7rem;
  line-height: 0.84rem;
  margin-bottom: 0.417rem;
`;

const StyledInput = styled.input`
  padding: 0.7rem;
  font-size: 0.972rem;
  line-height: 1.112rem;
  color: #f6f6f6;
  font-family: "Articulat CF Medium";
  border: 0.138rem solid #f6f6f6;
  border-radius: 0.84rem;
  outline: none;
  background: #2f2f2f;
  margin-right: 0.7rem;
  width: 72%;
  max-width: 72%;
  &::placeholder {
    font-family: Articulat CF Medium;
    color: #b0b0b0;
    font-size: 0.972rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const getButtonName = (step, isTestConnection) => {
  switch (step) {
    case 1:
      return isTestConnection ? "Test Connection" : "Configure";
    case 2:
      return "Deploy";
    default:
      return "";
  }
};

const filterValidInterfaces = (arr) => {
  return arr.filter(Boolean);
};

const ConnectorsConfigurationPage = () => {
  const { showMessage } = useContext(SnackbarContext);
  const location = useLocation();
  const history = useHistory();

  const [deviceData, setDeviceData] = useState([]);
  const [configs, setConfigs] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [isTestConnectionApplicable, setTestConnectionAppicable] =
    useState(false);
  const [isConfigureProcessing, setConfigureProcessing] = useState(false);
  const [configurationId, setConfigurationId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitleText, setModalTitleText] = useState("");
  const [previewData, setPreviewData] = useState({});
  const [content, setContent] = useState("");
  const [connectorVersionList, setConnectorVersionList] = useState([]);
  const [animatingLine, setAnimatingLine] = useState(null);
  const [selectedConnectorVersion, setSelectedConnectorVersion] = useState(
    location.state?.customConnectorVersion || "",
  );
  const [reqResources, setReqResources] = useState({
    cpu: "",
    memory: "",
  });

  // Function to close the success/fail modal
  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleConnectorsClick = useCallback(() => {
    history.push("/connectors");
  }, [history]);

  const handleModalButton = useCallback(() => {
    if (currentStep === 1) {
      handleModalClose();
      return;
    }

    // Successful redirect to playground page
    if (modalTitleText === "Deployment Successful!") {
      history.push({
        pathname: "connectorsPlayground",
        selectedWorkspace: localStorage.getItem("selectedWorkSpaceId"),
      });
    } else {
      handleModalClose();
    }
  }, [currentStep, handleModalClose, history, modalTitleText]);

  const setConnectorConfigsAndTestFunction = async (configArg) => {
    let tempConfigurationId;
    try {
      let stringifiedData = JSON.stringify({
        ...configArg,
        workspaceId: parseInt(localStorage.getItem("selectedWorkspaceId") || 0),
      });
      let setConfigsResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setConnectorConfigsNew",
        stringifiedData,
      );
      tempConfigurationId = setConfigsResponse.data.data.id;
      setConfigurationId(tempConfigurationId);
    } catch (err) {
      setModalOpen(true);
      setModalTitleText("Setting Configuration Failed");
      err.code = "CONFIG_ERROR";
      throw err;
    }
    if (isTestConnectionApplicable) {
      try {
        let stringifiedData = JSON.stringify({
          id: tempConfigurationId,
          connectorName: location.state?.selectedConnectorName,
          workspaceId: parseInt(
            localStorage.getItem("selectedWorkspaceId") || 0,
          ),
        });
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/testConnectorConfigsNew",
          stringifiedData,
        );
      } catch (err) {
        setModalOpen(true);
        setModalTitleText("Connection Failed");
        err.code = "TEST_ERROR";
        throw err;
      }
    }
  };
  function getModalTitle(modalTitleText) {
    switch (modalTitleText) {
      case "Configuration Successful!":
        return "You have successfully configured your connector, Please click continue.";
      case "Configuration Failed":
        return "Oops! Your configuration has failed. Try again.";
      case "Connection Successful!":
        return "You have successfully configured your connector, Please click continue.";
      case "Deployment Successful!":
        return "You have successfully deployed your connector. Please click continue.";
      case "Deployment Failed":
        return "Oops! Your deployment has failed. Try again.";
      default:
        return "An unexpected error occurred.";
    }
  }
  const goBackClickHandler = useCallback(() => {
    if (currentStep === 1) {
      history.push("/connectors");
    } else if (currentStep === 2) {
      setCurrentStep(1);
    }
  }, [currentStep, history]);

  // Validation helpers
  const validateRequiredField = (item, itemType = "") => {
    if (!item.isRequired || item.value) return;

    const message =
      itemType === "topic"
        ? `Please select or create a topic for the field ${item.label} (${item.direction})`
        : `Please enter value for the field '${item.label}'`;

    const error = new Error(message);
    error.code = "INPUT_ERROR";
    throw error;
  };

  const validatePortNumber = (item) => {
    if (item.type !== "port") return;

    const isValidPort =
      parseInt(item.value) >= item.min && parseInt(item.value) <= item.max;
    if (!isValidPort) {
      const error = new Error(
        `Please enter port number between ${item.min} and ${item.max} range`,
      );
      error.code = "INPUT_ERROR";
      throw error;
    }
  };

  const validateResourceLimits = (reqResources) => {
    const { cpu, memory } = reqResources;

    if (!cpu && !memory) return null;
    if (!(cpu && memory)) {
      const error = new Error(
        "Please either fill both the values of empty both for default in Request Limit Configuration",
      );
      error.code = "INPUT_ERROR";
      throw error;
    }

    const cpuValue = parseInt(cpu);
    const memoryValue = parseInt(memory);
    const isValid =
      cpuValue > 0 &&
      memoryValue > 0 &&
      cpuValue <= 2000 &&
      memoryValue <= 2000;

    if (!isValid) {
      const error = new Error(
        "Please enter Request Limit Configuration values between 1 and 2000",
      );
      error.code = "INPUT_ERROR";
      throw error;
    }

    return {
      cpu: cpuValue,
      memory: memoryValue,
    };
  };

  // Configuration builders
  const buildTelematicsConfig = (configs) => {
    if (!configs.length) {
      const error = new Error("Please select a device before configuration");
      error.code = "INPUT_ERROR";
      throw error;
    }

    const configObj = {};

    // Skip first item as it's handled separately
    for (let i = 1; i < configs.length; i++) {
      const item = configs[i];

      if (item.type === "topic") {
        validateRequiredField(item, "topic");
        if (item.value?.name) {
          configObj[item.name] = item.value.name;
        }
      } else {
        validateRequiredField(item);
        configObj[item.name] = item.value;
      }
    }

    return {
      connectorName: configs[0].value,
      type: "Input",
      config: configObj,
    };
  };

  const buildStandardConfig = (
    configs,
    locationState,
    selectedConnectorVersion,
  ) => {
    const configObj = {};

    configs.forEach((item) => {
      if (item.type === "topic") {
        validateRequiredField(item, "topic");
        if (item.value?.name) {
          configObj[item.name] = item.value.name;
        }
      } else {
        validateRequiredField(item);
        validatePortNumber(item);
        configObj[item.name] = item.value;
      }
    });

    const config = {
      connectorName: locationState?.selectedConnectorName,
      type: locationState?.selectedConnectorType,
      config: configObj,
    };

    if (locationState?.selectedConnectorType === "Custom Connector") {
      config.customConnectorId = locationState?.selectedConnectorId;
      config.customConnectorVersion = selectedConnectorVersion;

      const resources = validateResourceLimits(reqResources);
      if (resources) {
        config.resource = resources;
      }
    }

    return config;
  };

  // Error handler
  const handleConfigurationError = (err, showMessage) => {
    const errorMessages = {
      INPUT_ERROR: err.message,
      CONFIG_ERROR: "Failed to set the configuration of this connector",
      TEST_ERROR:
        "Failed during Connection Test of the Connector Configurations",
      DEFAULT: "Failed to configure the selected connector",
    };

    showMessage(errorMessages[err.code] || errorMessages.DEFAULT);
    console.log("err handleNextClick", err);
  };

  // Main handler function
  const handleNextClick = async () => {
    if (currentStep === 1) {
      try {
        setConfigureProcessing(true);

        const configArg =
          location.state?.selectedConnectorCategory === "Telematics Device"
            ? buildTelematicsConfig(configs)
            : buildStandardConfig(
                configs,
                location.state,
                selectedConnectorVersion,
              );

        await setConnectorConfigsAndTestFunction(configArg);

        setCurrentStep(2);
        setModalOpen(true);
        setModalTitleText(
          location.state?.selectedConnectorCategory === "Telematics Device"
            ? "Configuration Successful!"
            : "Connection Successful!",
        );

        setPreviewData((prevData) => {
          const tempInterfaces = filterValidInterfaces(prevData.interfaces);
          return {
            ...prevData,
            interfaces: tempInterfaces,
            firstNode: tempInterfaces.every((obj) => obj.direction !== "input"),
          };
        });
      } catch (err) {
        handleConfigurationError(err, showMessage);
      } finally {
        setConfigureProcessing(false);
      }
    }

    if (currentStep === 2) {
      history.push("/deployConnector", {
        ...(location.state || {}),
        configurationId,
        newConnectorObject: {
          id: configurationId.toString(),
          type: location.state?.selectedConnectorType,
          data: {
            ...previewData,
            configId: configurationId,
          },
          targetPosition: "left",
          sourcePosition: "right",
          position: { x: null, y: null },
        },
      });
    }
  };

  const fetchAllTopics = useCallback(async () => {
    try {
      let allTopicsResponse = await axiosAdapter(
        "GET",
        env.REACT_APP_URL + "kafkaadmin/getAllTopics",
      );
      setAllTopics(allTopicsResponse.data.data.topics);
    } catch (err) {
      console.log("Error: getAllTopics", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to fetch topics !!!");
      }
      setAllTopics([]);
    }
  }, [showMessage]);

  const fetchCustomConnectorUserConfigs = useCallback(
    async (customConnectorId, customConnectorVersion) => {
      try {
        let stringifiedData = JSON.stringify({
          customConnectorId,
          customConnectorVersion,
          workspaceId: parseInt(
            localStorage.getItem("selectedWorkspaceId") || 0,
          ),
        });
        let fetchConfigResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getCustomConnectorUserConfigs",
          stringifiedData,
        );
        setConfigs(
          fetchConfigResponse.data.data.map((obj) => ({
            value: "",
            ...obj,
          })),
        );
      } catch (err) {
        console.log("Error: getCustomConnectorUserConfigs", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Unable to fetch custom connector configurations !!!");
        }
        setConfigs([]);
      }
    },
    [showMessage],
  );

  // Function to fetch the devices for the manufacturer id
  const fetchDevicesforManfacturer = useCallback(async () => {
    try {
      let stringifiedData = JSON.stringify({
        workspaceId: parseInt(localStorage.getItem("selectedWorkspaceId") || 0),
        manufacturerId: location.state?.selectedConnectorId,
      });

      let fetchedDevicesforManufacturer = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "admin/getAllTelematicsDevicesForManufacturer",
        stringifiedData,
      );

      setDeviceData(fetchedDevicesforManufacturer.data.data);

      setPreviewData((prev) => ({
        ...prev,
        connectionType: location.state?.selectedConnectorType,
        category: location.state?.selectedConnectorCategory,
        name: location.state?.selectedConnectorName,
        description: location.state?.selectedConnectorDescription,
        interfaces: [],
      }));
    } catch (err) {
      console.log("Error: getAllTelematicsDevicesForManufacturer", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to fetch the devices for the selected connector");
      }
      setDeviceData([]);
    }
  }, [location.state, showMessage]);

  // Function to fetch the configs and topics for non-telematics devices
  const fetchConfigsAndTopics = useCallback(async () => {
    try {
      let stringifiedData = JSON.stringify({
        connectorName: location.state?.selectedConnectorName,
        type: location.state?.selectedConnectorType,
      });

      let fetchConfigResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getConnectorConfigs",
        stringifiedData,
      );
      setContent(fetchConfigResponse.data.data.templateContent);
      setConfigs(
        fetchConfigResponse.data.data.configJson.config.map((obj) => ({
          value: "",
          ...obj,
        })),
      );

      setPreviewData((prev) => ({
        ...prev,
        connectionType: location.state?.selectedConnectorType,
        category: location.state?.selectedConnectorCategory,
        name: location.state?.selectedConnectorName,
        description: location.state?.selectedConnectorDescription,
        interfaces: [],
      }));

      if (fetchConfigResponse.data.data.configJson.testConnection === true) {
        setTestConnectionAppicable(true);
      }
    } catch (err) {
      console.log("Error: getConnectorConfigs", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to fetch the configs for the selected connector");
      }
      setConfigs([]);
    }
  }, [location.state, showMessage]);

  const fetchCustomConnectorVersions = useCallback(async () => {
    try {
      const getAllCustomConnectorVersionsResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getAllCustomConnectorVersions",
        JSON.stringify({
          customConnectorId: location.state?.selectedConnectorId,
          workspaceId: parseInt(
            localStorage.getItem("selectedWorkspaceId") || 0,
          ),
        }),
      );
      setConnectorVersionList(
        getAllCustomConnectorVersionsResponse.data.data.versions,
      );
    } catch (err) {
      console.log("Error: getAllCustomConnectorVersions", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to fetch the versions for the selected connector");
      }
      setConnectorVersionList([]);
    }
  }, [location.state?.selectedConnectorId, showMessage]);

  const fetchCustomConnectorConfigs = useCallback(async () => {
    try {
      await fetchCustomConnectorUserConfigs(
        location.state?.selectedConnectorId,
        selectedConnectorVersion,
      );

      setPreviewData((prev) => ({
        ...prev,
        connectionType: location.state?.selectedConnectorType,
        category: location.state?.selectedConnectorCategory,
        name: location.state?.selectedConnectorName,
        description: location.state?.selectedConnectorDescription,
        interfaces: [],
      }));
    } catch (err) {
      console.log("Error: fetchCustomConnectorUserConfigs", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to fetch the configs for the selected connector");
      }
      setConfigs([]);
    }
  }, [
    fetchCustomConnectorUserConfigs,
    location.state,
    selectedConnectorVersion,
    showMessage,
  ]);

  useEffect(() => {
    fetchAllTopics();
    if (location.state?.selectedConnectorCategory === "Telematics Device") {
      fetchDevicesforManfacturer();
    } else if (location.state?.selectedConnectorCategory === "Application") {
      fetchCustomConnectorConfigs();
      fetchCustomConnectorVersions();
    } else {
      fetchConfigsAndTopics();
    }
  }, [
    location.state,
    fetchAllTopics,
    fetchDevicesforManfacturer,
    fetchCustomConnectorConfigs,
    fetchCustomConnectorVersions,
    fetchConfigsAndTopics,
  ]);

  useEffect(() => {
    if (selectedConnectorVersion && location.state?.selectedConnectorId) {
      fetchCustomConnectorUserConfigs(
        location.state?.selectedConnectorId,
        selectedConnectorVersion,
      );
    }
  }, [
    selectedConnectorVersion,
    fetchCustomConnectorUserConfigs,
    location.state?.selectedConnectorId,
  ]);

  if (!location.state?.isRedirected) {
    history.push("/connectors");
  }

  const isConfigDisabled =
    (!configs.length && !deviceData.length) ||
    isConfigureProcessing ||
    (location.state?.selectedConnectorType === "Custom Connector" &&
      !selectedConnectorVersion.length);

  return (
    <div
      style={{
        backgroundColor: "#171717",
        height: "calc(100vh - 3rem)",
        padding: "1.112rem",
      }}
    >
      <CustomRow style={{ display: "flex", alignItems: "center" }}>
        <Column xs={6}>
          <NavbarContainer>
            <NavbarLeftConnect>
              <Icon onClick={handleConnectorsClick}>
                <img
                  src={backIcon}
                  alt="Back Icon"
                  style={{ width: "2.225rem", height: "2.225rem" }}
                />
              </Icon>
              <Title>{`Configure ${
                location.state?.selectedConnectorName || "Your Connector"
              }`}</Title>
            </NavbarLeftConnect>
          </NavbarContainer>
        </Column>
        <Column xs={5} style={{ marginBottom: "1.112rem" }}>
          <Stepper
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setAnimatingLine={setAnimatingLine}
            animatingLine={animatingLine}
          />
        </Column>
      </CustomRow>
      <CustomRow>
        <Column xs={4.1} className="ConnectorConfigPageColumn">
          <ConfigForm
            deviceData={deviceData}
            configs={configs}
            setConfigs={setConfigs}
            allTopics={allTopics}
            setAllTopics={setAllTopics}
            setPreviewData={setPreviewData}
            connectorVersionList={connectorVersionList}
            setSelectedConnectorVersion={setSelectedConnectorVersion}
            selectedConnectorVersion={selectedConnectorVersion}
          />
        </Column>
        <Column xs={7.9} className="ConnectorConfigPageColumn addFlex">
          <MoreInfoPart content={content} />
          <CustomRow style={{ height: "35.5%" }}>
            {location.state?.selectedConnectorCategory === "Application" && (
              <Column
                xs={4}
                style={{
                  height: "100%",
                  borderRadius: "0.84rem",
                  backgroundColor: "#2F2F2F",
                  padding: "1.112rem",
                  overflow: "auto",
                  marginLeft: "1.07rem",
                }}
              >
                <CustomRow style={{ rowGap: "0.55rem" }}>
                  <Column>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.25rem",
                        lineHeight: "1.39rem",
                      }}
                    >
                      Configure Request Limits
                    </Text>
                  </Column>
                  <Column
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <Label>Memory</Label>
                    <InputContainer>
                      <StyledInput
                        placeholder="Enter Memory Value"
                        type="number"
                        value={reqResources.memory}
                        onChange={(e) => {
                          setReqResources((prev) => ({
                            ...prev,
                            memory: e.target.value,
                          }));
                        }}
                        max={2000}
                      />
                      <Text style={{ fontSize: "0.972rem" }}>in MB</Text>
                    </InputContainer>
                  </Column>
                  <Column
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <Label>CPU</Label>
                    <InputContainer>
                      <StyledInput
                        placeholder="Enter CPU Value"
                        type="number"
                        value={reqResources.cpu}
                        onChange={(e) => {
                          setReqResources((prev) => ({
                            ...prev,
                            cpu: e.target.value,
                          }));
                        }}
                        max={2000}
                      />
                      <Text style={{ fontSize: "0.972rem" }}>in mCPU</Text>
                    </InputContainer>
                  </Column>
                </CustomRow>
              </Column>
            )}
            <Column
              xs={
                location.state?.selectedConnectorCategory === "Application"
                  ? 7.8
                  : 12
              }
              style={{ height: "100%" }}
            >
              <PreviewContainer>
                <PreviewTitle>Preview</PreviewTitle>
                <ReactFlowNodeCard data={previewData} />
              </PreviewContainer>
            </Column>
          </CustomRow>
        </Column>
      </CustomRow>
      <ConnectorConfigBottomButtonContainer>
        <StyledButtonsecondary
          onClick={goBackClickHandler}
          style={{ width: "14.167rem" }}
        >
          Go Back
        </StyledButtonsecondary>
        <StyledButtonPrimary
          style={{ width: "14.167rem", marginLeft: "1.39rem" }}
          disabled={isConfigDisabled}
          onClick={() => handleNextClick()}
        >
          {getButtonName(currentStep, isTestConnectionApplicable)}
        </StyledButtonPrimary>
      </ConnectorConfigBottomButtonContainer>
      <GenericModal
        onClose={() => handleModalClose()}
        show={isModalOpen}
        title={modalTitleText}
      >
        <ModalDescription>{getModalTitle(modalTitleText)}</ModalDescription>
        <ModalButtonContainer>
          <StyledButtonsecondary
            onClick={() => handleModalClose()}
            disabled={modalTitleText === "Deployment Successful!"}
            style={{ width: "14.167rem" }}
          >
            Cancel
          </StyledButtonsecondary>
          {modalTitleText === "Deployment Failed" ? (
            <StyledDeleteButton
              style={{
                width: "14.167rem",
              }}
              onClick={() => handleModalButton()}
            >
              Try Again
            </StyledDeleteButton>
          ) : (
            <StyledButtonPrimary
              style={{
                width: "14.167rem",
              }}
              onClick={() => handleModalButton()}
            >
              {modalTitleText === "Connection Successful!" ||
              modalTitleText === "Configuration Successful!" ||
              modalTitleText === "Deployment Successful!"
                ? "Continue"
                : "Try Again"}
            </StyledButtonPrimary>
          )}
        </ModalButtonContainer>
      </GenericModal>
    </div>
  );
};

export default ConnectorsConfigurationPage;
